import React from "react";
import Timer from "../Timer/Timer";
import { useScroll } from "../../../../context/ScrollContext";
import { useTimer } from "../../../../context/TimerContext";
import { usePageConfig } from "../../../../context/PageConfigContext";

import styles from "./TopDiscount.module.css";
import LogoGlobal from "../../../templates/Logo/LogoGlobal";

const TopDiscount = () => {
  const { findDataComponent } = usePageConfig();
  const { showHideElement } = useTimer();
  const { scrollToComponent } = useScroll();
  const dataComponent = findDataComponent("TopDiscount");

  const handleButtonClick = () => {
    setTimeout(() => {
      scrollToComponent();
    }, 50);
  };

  return (
    <>
      {showHideElement && (
        <div className={styles.containerDiscount}>
          <div className={styles.topLogo}>
            <LogoGlobal />
          </div>
          <div className={styles.topContent}>
            <div className={styles.containerDiscountInfo}>
              <div className={styles.containerDiscountTitle}>
                {dataComponent.text}
              </div>
              <div className={styles.containerDiscountTimer}>
                <Timer />
              </div>
            </div>
            <button
              className={styles.containerDiscountButton}
              onClick={handleButtonClick}
            >
              <span className={styles.containerDiscountButtonText}>
                {dataComponent.newButtonText}
              </span>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default TopDiscount;
