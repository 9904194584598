import React from "react";

import { usePageConfig } from "../../../../context/PageConfigContext";

import FooterBlock from "./FooterBlock/FooterBlock";

import AssetsContainer from "./AssetsContainer/AssetsContainer";
import HeaderVariantB from "../ChoosePlan/HeaderRender/HeaderVariantB";
import VariantC from "../ABTestPlanContainer/VariantC/VariantС";

import styles from "./SecondChoosePlan.module.css";

const SecondChoosePlan = () => {
  const { findDataComponent } = usePageConfig();

  const data = findDataComponent("SecondChoosePlan");

  return (
    <div className={styles.ChoosePlanContainer}>
      <div className={styles.optionsCont}>
        <HeaderVariantB />
        <VariantC />
        <FooterBlock />
      </div>
      <AssetsContainer data={data} />
    </div>
  );
};

export default SecondChoosePlan;
