import React from "react";

import styles from "./PlanDetails.module.css";

import VariantC from "./ABTestDesignVariants/VariantC";

const PlanDetails = () => {
  return (
    <div className={styles.PlanDetailsContainer}>
      <VariantC />
    </div>
  );
};

export default PlanDetails;
