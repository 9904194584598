import React, { createContext, useContext, useState, useEffect } from "react";

import { useRemoteConfig } from "./RemoteConfigContext";

const PromoCounterContext = createContext();

export const usePromoCounter = () => useContext(PromoCounterContext);

export const PromoCounterProvider = ({ children }) => {
  const { peopleOnWaitlist } = useRemoteConfig();

  const [counter, setCounter] = useState(null);

  useEffect(() => {
    if (peopleOnWaitlist) {
      setCounter(peopleOnWaitlist);
    }
  }, [peopleOnWaitlist]);

  useEffect(() => {
    if (counter === null) return;

    // const getRandomIncrement = () => Math.floor(Math.random() * 2) + 1;
    const getRandomInterval = () =>
      Math.floor(Math.random() * (4000 - 1000 + 1)) + 1000;

    const updateCounter = () => {
      setCounter((prevCounter) => {
        if (prevCounter === null) return prevCounter;
        // const increment = getRandomIncrement();
        const increment = 1;
        return prevCounter + increment;
      });
    };

    const nextUpdate = () => {
      const interval = getRandomInterval();
      return setTimeout(() => {
        updateCounter();
        timeoutRef.current = nextUpdate();
      }, interval);
    };

    const timeoutRef = { current: null };
    timeoutRef.current = nextUpdate();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [counter]);

  const formattedCounter = counter?.toLocaleString("en-US") || "0";

  const exportValue = {
    counter: formattedCounter,
  };

  return (
    <PromoCounterContext.Provider value={exportValue}>
      {children}
    </PromoCounterContext.Provider>
  );
};
