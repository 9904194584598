import React from "react";

import styles from "./LogoHPstyle.module.css";

const LogoHP = ({ styleData }) => {
  const defaultStyle = {
    width: "130px",
    height: "41px",
  };

  return (
    <img
      src="../img/newLogoHP.png"
      alt="newLogoHP"
      className={styles.logoImg}
      style={{
        width: styleData ? styleData.width : defaultStyle.width,
        height: styleData ? styleData.height : defaultStyle.height,
      }}
    />
  );
};

export default LogoHP;
