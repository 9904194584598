import React from "react";
import { usePageContent } from "../../../../../context/PageContext";

import styles from "./ActiveDownsaleStyle.module.css";

const ActiveDownsale = () => {
  const { pageContent } = usePageContent();

  return (
    <div className={styles.container}>
      <img
        src="../img/moSalePlanPage.png"
        alt="moSalePlanPage"
        className={styles.saleImg}
      />
      <div className={styles.saleTextContainer}>
        <span className={styles.textGreen}>
          {pageContent.down_sale_block.text_part.map((text, index) => (
            <span key={index} style={{ color: text.text_color }}>
              {text.text}{" "}
            </span>
          ))}
        </span>
      </div>
    </div>
  );
};
export default ActiveDownsale;
