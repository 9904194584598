import React from "react";

import styles from "./ImgBlockStyle.module.css";

const ImgBlock = () => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.imgContainer}>
        <img
          src="../img/brain_sick.png"
          alt="sick_brain"
          className={styles.brainImg}
        />
      </div>
      <img src="../img/arrows.png" alt="arrows" className={styles.arrowsImg} />
      <div className={styles.imgContainer}>
        <img
          src="../img/brain_win.png"
          alt="brain_winner"
          className={styles.brainImg}
        />
      </div>
    </div>
  );
};

export default ImgBlock;
