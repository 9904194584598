import React, { useState } from "react";

import { useRemoteConfig } from "../../../../context/RemoteConfigContext";

import styles from "./FooterStyle.module.css";

const Footer = () => {
  const { reservePageData } = useRemoteConfig();

  const [data] = useState(reservePageData.footer);

  return (
    <div className={styles.container}>
      <div className={styles.bottomContainer}>
        <div className={styles.hrLine} />
        <div className={styles.compInfoContainer}>
          <a
            href={data.terms[1]}
            className={styles.compInfoLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {data.terms[0]}
          </a>
          <div className={styles.hrVertLine} />
          <a
            href={data.cookie[1]}
            className={styles.compInfoLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {data.cookie[0]}
          </a>
          <div className={styles.hrVertLine} />
          <a
            href={data.privacy[1]}
            className={styles.compInfoLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {data.privacy[0]}
          </a>
        </div>
        <span className={styles.compInfoText}>{data.company}</span>
      </div>
    </div>
  );
};

export default Footer;
