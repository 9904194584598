import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useNavigationPath } from "../../../hooks/navigationPath";
import { preserveUrlParams } from "../../../utils/urlParams";

import { useRemoteConfig } from "../../../context/RemoteConfigContext";
import { useButtonRef } from "../../../context/ButtonRefContext";

import styles from "./RegInterestStyle.module.css";

const RegInterest = () => {
  const { waitlistPageData } = useRemoteConfig();
  const { paymentButtonRef } = useButtonRef();

  const [data] = useState(waitlistPageData.reg_interest_block);

  const navigate = useNavigate();

  const reservePath = useNavigationPath("reserve");

  const handleButtonClick = () => {
    const userData = JSON.parse(localStorage.getItem("MemoryOSUserData"));

    if (userData) {
      navigate(preserveUrlParams(reservePath));
    } else if (!userData && paymentButtonRef.current) {
      paymentButtonRef.current.click();
    }
  };
  return (
    <div className={styles.shadowContainer}>
      <img
        src="../img/imgs-ChosePlan/Space_Spaceship.png"
        alt=""
        className={styles.leftImg}
      />
      <img
        src="../img/imgs-ChosePlan/Medieval_ShieldSword.png"
        alt=""
        className={styles.rightImg}
      />
      <div className={styles.container}>
        <span className={styles.title}>{data.title}</span>
        <button onClick={handleButtonClick} className={styles.button}>
          <span className={styles.buttonText}>{data.button_text}</span>
        </button>
      </div>
    </div>
  );
};

export default RegInterest;
