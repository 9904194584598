import React, { useEffect, useState } from "react";

import { usePageContent } from "../../../../../context/PageContext";
import { useTimer } from "../../../../../context/TimerContext";

import styles from "../ChoosePlan.module.css";

const HeaderVariantB = () => {
  const [renderText, setRenderText] = useState("");

  const { pageContent } = usePageContent();

  const { timer } = useTimer();

  useEffect(() => {
    if (
      timer >= 0 &&
      pageContent.changing_title_text_after_timer_expires === true
    ) {
      setRenderText(pageContent.first_subscription_options.title_text[1]);
    } else {
      setRenderText(pageContent.first_subscription_options.title_text[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  return <div className={styles.HeaderText}>{renderText}</div>;
};

export default HeaderVariantB;
