import React from "react";

import CustomBackButton from "../../components/CustomBackButton/CustomBackButton";

const ButtonModule = ({ currentStep, steps, handleBackStep }) => {
  return (
    <>
      {currentStep !== 0 &&
        steps.length &&
        steps[currentStep].showBackButton && (
          <CustomBackButton handleBackStep={handleBackStep} />
        )}
    </>
  );
};

export default ButtonModule;
