import React, { useState } from "react";
import { useRemoteConfig } from "../../../../context/RemoteConfigContext";
import { usePageContent } from "../../../../context/PageContext";

import styles from "./MissionComponentStyle.module.css";

const MissionComponent = () => {
  const { reservePageData } = useRemoteConfig();
  const { downSaleStatus } = usePageContent();

  const [data] = useState(reservePageData.mission_component);

  const styleMap = [
    {
      borderTop: "2px solid rgba(155, 79, 172, 0.5)",
      borderRight: "2px solid rgba(155, 79, 172, 0.5)",
      borderBottom: "2px solid rgba(155, 79, 172, 0.5)",
      borderLeft: "1px solid rgba(155, 79, 172, 0.3)",
      background: "linear-gradient(283.43deg, #551f4d -2.25%, #151320 20.58%)",
      imgPath: "../img/frame_0_reserve.png",
      alt: "frame_0_reserve",
    },
    {
      borderTop: "2px solid rgba(93, 79, 172, 0.5)",
      borderRight: "2px solid rgba(93, 79, 172, 0.5)",
      borderBottom: "2px solid rgba(93, 79, 172, 0.5)",
      borderLeft: "1px solid rgba(93, 79, 172, 0.3)",
      background: "linear-gradient(283.43deg, #2A1F55 -2.25%, #151320 20.58%)",
      imgPath: "../img/frame_1_reserve.png",
      alt: "frame_1_reserve",
    },
    {
      borderTop: "2px solid rgba(79, 172, 138, 0.5)",
      borderRight: "2px solid rgba(79, 172, 138, 0.5)",
      borderBottom: "2px solid rgba(79, 172, 138, 0.5)",
      borderLeft: "1px solid rgba(79, 172, 138, 0.3)",
      background: "linear-gradient(283.43deg, #1F554B -2.25%, #151320 20.58%)",
      imgPath: "../img/frame_2_reserve.png",
      alt: "frame_2_reserve",
    },
  ];

  return (
    <div className={styles.container}>
      <span className={styles.title}>{data.title}</span>
      <div className={styles.elementsContainer}>
        {data.text_block.map((element, index) => {
          return (
            <div
              className={styles.element}
              key={index}
              style={{
                borderTop: styleMap[index].borderTop,
                borderRight: styleMap[index].borderRight,
                borderBottom: styleMap[index].borderBottom,
                borderLeft: styleMap[index].borderLeft,
                background: styleMap[index].background,
              }}
            >
              <img
                src={styleMap[index].imgPath}
                alt={styleMap[index].alt}
                className={styles.elementImg}
              />
              <span className={styles.elementTitle}>{element.title}</span>
              <span className={styles.elementText}>
                {Array.isArray(element.main_text)
                  ? !downSaleStatus
                    ? element.main_text[0]
                    : element.main_text[1]
                  : element.main_text}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default MissionComponent;
