import React from "react";
import { useNavigate } from "react-router-dom";
import { useNavigationPath } from "../../../../hooks/navigationPath";
import { preserveUrlParams } from "../../../../utils/urlParams";

import { usePageContent } from "../../../../context/PageContext";
import { useButtonRef } from "../../../../context/ButtonRefContext";

import styles from "./MainButtonStyle.module.css";

const MainButton = ({ width, height, btnText, undBtnText }) => {
  const { paymentButtonRef } = useButtonRef();
  const { downSaleStatus, setShowHideDownSalePopup, activateDiscount } =
    usePageContent();

  const navigate = useNavigate();

  const thankyouPath = useNavigationPath("thankyou");

  const handleButtonClick = () => {
    if (paymentButtonRef.current) {
      paymentButtonRef.current.click();
    }
  };

  const noButtonClick = () => {
    if (downSaleStatus !== true) {
      if (activateDiscount) {
        setShowHideDownSalePopup(true);
      }
    } else {
      navigate(preserveUrlParams(thankyouPath));
    }
  };

  return (
    <button
      className={styles.mainBtnContainer}
      style={{
        width: width,
        height: height,
      }}
    >
      <div className={styles.button} onClick={handleButtonClick}>
        <div className={styles.btnTextContainer}>
          <span className={styles.buttonText}>{btnText}</span>
        </div>
        <div className={styles.starOneL} />
        <div className={styles.starTwoL} />
        <div className={styles.starThreeL} />
        <div className={styles.starOneR} />
        <div className={styles.starTwoR} />
        <div className={styles.starThreeR} />
      </div>
      {undBtnText && (
        <div className={styles.underBtnContainer} onClick={noButtonClick}>
          <span className={styles.underBtnText}>{undBtnText}</span>
          <img
            src="../img/no_chanse_arrow.png"
            alt="no_chanse_arrow"
            className={styles.imgArrow}
          />
        </div>
      )}
    </button>
  );
};

export default MainButton;
