import { useState, useEffect } from "react";

export const useUrlChange = () => {
  const [currentUrl, setCurrentUrl] = useState(window.location.href);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (currentUrl !== window.location.href) {
        setCurrentUrl(window.location.href);
      }
    });

    observer.observe(document, { subtree: true, childList: true });

    return () => observer.disconnect();
  }, [currentUrl]);

  return currentUrl;
};
