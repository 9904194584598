import React, { createContext, useContext, useRef } from "react";

const ScrollContext = createContext(null);

export const ScrollProvider = ({ children }) => {
  const scrollRefOne = useRef(null);

  const scrollToComponent = () => {
    if (scrollRefOne.current && scrollRefOne.current.scrollIntoView) {
      scrollRefOne.current.scrollIntoView({ behavior: "smooth" });
    } else {
      console.error("Scroll target not found");
    }
  };

  const scrollRefTop = useRef(null);

  const scrollToTopComponent = () => {
    if (scrollRefTop.current && scrollRefTop.current.scrollIntoView) {
      scrollRefTop.current.scrollIntoView({ behavior: "smooth" });
    } else {
      console.error("Scroll target not found");
    }
  };

  const contextValues = {
    scrollRefOne,
    scrollToComponent,
    scrollRefTop,
    scrollToTopComponent,
  };

  return (
    <ScrollContext.Provider value={contextValues}>
      {children}
    </ScrollContext.Provider>
  );
};

export const useScroll = () => useContext(ScrollContext);
