import React from "react";
import {
  Plan,
  Column,
  Title,
  PlanInner,
  PlanColumn,
  SubTitle,
  Card,
  CardImage,
  CardTitle,
  CardRating,
  Star,
  CardDesription,
  PlanInnerBottom,
  StickyContainer,
  Button,
} from "../../styles";

const PlanStep = ({ id, step, handleNextStep, userData }) => {
  return (
    <Plan>
      <PlanInner>
        <PlanColumn>
          <div>
            <SubTitle>BASED ON YOUR ANSWERS</SubTitle>
            <Title>This course fits you most</Title>
          </div>
          <Button
            $isActive={true}
            onClick={() => handleNextStep({ id, type: "plan" })}
          >
            Let's start!
          </Button>
        </PlanColumn>
        <Column>
          <Card>
            <CardImage src={step.plans[0].image} alt="" />
            <CardTitle>{step.plans[0].title}</CardTitle>
            <CardRating>
              {[...Array(5)].map((item, i) => (
                <Star
                  key={`star${id}-0`}
                  $isActive={!!(i < step.plans[0].rating)}
                />
              ))}
            </CardRating>
            <CardDesription>{step.plans[0].descrition}</CardDesription>
          </Card>
        </Column>
      </PlanInner>
      <PlanInnerBottom>
        <Title>You might also like</Title>
        <Card>
          <CardImage src={step.plans[1].image} alt="" />
          <CardTitle>{step.plans[1].title}</CardTitle>
          <CardRating>
            {[...Array(5)].map((item, i) => (
              <Star
                key={`star${id}-1`}
                $isActive={!!(i < step.plans[1].rating)}
              />
            ))}
          </CardRating>
          <CardDesription>{step.plans[1].descrition}</CardDesription>
        </Card>
        <Card>
          <CardImage src={step.plans[2].image} alt="" />
          <CardTitle>{step.plans[2].title}</CardTitle>
          <CardRating>
            {[...Array(5)].map((item, i) => (
              <Star
                key={`star${id}-2`}
                $isActive={!!(i < step.plans[2].rating)}
              />
            ))}
          </CardRating>
          <CardDesription>{step.plans[2].descrition}</CardDesription>
        </Card>
      </PlanInnerBottom>
      <StickyContainer>
        <Button
          isActive={true}
          onClick={() => {
            handleNextStep({ id, type: "plan" });
          }}
        >
          Let's start!
        </Button>
      </StickyContainer>
    </Plan>
  );
};

export default PlanStep;
