import React from "react";

import styles from "./VideoLoaderStyle.module.css";

const VideoLoader = () => {
  const importStyle = {
    border: `7px solid rgb(62, 32, 183)`,
    borderBottomColor: `rgb(69, 72, 215)`,
  };
  return (
    <div className={styles.container}>
      <div className={styles.loader} style={importStyle}></div>
    </div>
  );
};
export default VideoLoader;
