import React, { useState } from "react";
import { useRemoteConfig } from "../../../../context/RemoteConfigContext";
import { usePageContent } from "../../../../context/PageContext";

import LogoHP from "../../../templates/LogoHP/LogoHP";
import MainButton from "../MainButton/MainButton";

import styles from "./MainComponentStyle.module.css";

const MainComponent = () => {
  const { reservePageData } = useRemoteConfig();

  const { downSaleStatus } = usePageContent();

  const [data] = useState(reservePageData.info_component);

  const logoStyle = { width: "151px", height: "45px" };

  return (
    <div className={styles.container}>
      <div className={styles.mainContent}>
        <LogoHP styleData={logoStyle} />
        <div className={styles.partTextContainer}>
          {data.title_text.map((text, index) => {
            return (
              <span key={index} className={styles.textParts}>
                {index === 1 ? (!downSaleStatus ? text[0] : text[1]) : text}
              </span>
            );
          })}
        </div>
        <div className={styles.buttonContainer}>
          <MainButton
            width={"284px"}
            height={"52px"}
            btnText={data.button_text}
          />
        </div>

        {!downSaleStatus && (
          <span className={styles.bottomText}>{data.under_button_text}</span>
        )}

        {downSaleStatus && (
          <span
            className={styles.bottomText}
          >
            {data.discount_under_button_text.map((text, index) => (
              <React.Fragment key={index}>
                {index === 1 || index === 3 ? (
                  <span className={styles.bottomTextSpecial}>{text}</span>
                ) : (
                  text
                )}{" "}
              </React.Fragment>
            ))}
          </span>
        )}
      </div>
    </div>
  );
};

export default MainComponent;
