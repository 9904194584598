import React, { useEffect } from "react";
import { useRemoteConfig } from "../../../context/RemoteConfigContext";
import { TimerProvider } from "../../../context/TimerContext";
import { useErrorHandler } from "../../../context/ErrorHandlerContext";
import { useEvent } from "../../../context/EventContext";
import { usePageContent } from "../../../context/PageContext";

import PageComponent from "../templates/PageComponent/PageComponent";
import ContainerComponent from "../templates/ContainerComponent/ContainerComponent";

import TopDiscount from "./TopDiscount/TopDiscount";
import TopDoubleComponent from "./TopDoubleComponent/TopDoubleComponent";
import ChoosePlan from "./ChoosePlan/ChoosePlan";
import PrizesContainer from "./PrizesContainer/PrizesContainer";
import WhatIsMemoryOS from "./WhatIsMemoryOS/WhatIsMemoryOS";
import WithinMemoryOS from "./WithinMemoryOS/WithinMemoryOS";
import YourGrandmaster from "./YourGrandmaster/YourGrandmaster";
import InfoImgBlock from "./InfoImgBlock/InfoImgBlock";
import MoreEfficiently from "./MoreEfficiently/MoreEfficiently";
import DidYouKnow from "./DidYouKnow/DidYouKnow";
import ReviewContainer from "./ReviewContainer/ReviewContainer";
import AnswerQuestions from "./AnswerQuestions/AnswerQuestions";
import SecondChoosePlan from "./ChoosePlanSecond/SecondChoosePlan";
import MoneyBackGuarantee from "./MoneyBackGuarantee/MoneyBackGuarantee";
import ViewingPageCounter from "./ViewingPageCounter/ViewingPageCounter";
import JoinTheRest from "./JoinTheRest/JoinTheRest";
import PleaseNote from "./PleaseNote/PleaseNote";
import FooterContainer from "./FooterContainer/FooterContainer";
import DownSalePopup from "./DownSalePopup/DownSalePopup";
import PaymentBlock from "./PaymentBlock/PaymentBlock";

import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import SpecialOfferPopup from "./SpecialOfferPopup/SpecialOfferPopup";

const ABspecialOfferPlan = () => {
  const { showError } = useErrorHandler();
  const { componentsData } = useRemoteConfig();
  const { analyticsEvent } = useEvent();

  const {
    showHideDownSalePopup,
    showHideSpecialOfferPopup,
    productProcessing,
  } = usePageContent();

  useEffect(() => {
    analyticsEvent("planPageView");
    productProcessing(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getComponentByName = (name) => {
    switch (name) {
      case "TopDiscount":
        return <TopDiscount />;
      case "TopDoubleComponent":
        return <TopDoubleComponent />;
      case "ChoosePlan":
        return <ChoosePlan />;
      case "PrizesContainer":
        return <PrizesContainer />;
      case "WhatIsMemoryOS":
        return <WhatIsMemoryOS />;
      case "WithinMemoryOS":
        return <WithinMemoryOS />;
      case "YourGrandmaster":
        return <YourGrandmaster />;
      case "InfoImgBlock":
        return <InfoImgBlock />;
      case "MoreEfficiently":
        return <MoreEfficiently />;
      case "DidYouKnow":
        return <DidYouKnow />;
      case "ReviewContainer":
        return <ReviewContainer />;
      case "AnswerQuestions":
        return <AnswerQuestions />;
      case "SecondChoosePlan":
        return <SecondChoosePlan />;
      case "MoneyBackGuarantee":
        return <MoneyBackGuarantee />;
      case "ViewingPageCounter":
        return <ViewingPageCounter />;
      case "JoinTheRest":
        return <JoinTheRest />;
      case "PleaseNote":
        return <PleaseNote />;

      default:
        return null;
    }
  };
  const backgroundColor = "none";
  const styleOpt = { width: "100%" };

  return (
    <TimerProvider>
      <PageComponent bgColor={backgroundColor}>
        <ContainerComponent styleOption={styleOpt}>
          {showError ? <ErrorHandler /> : null}
          {componentsData
            .sort((a, b) => a.position - b.position)
            .map((item, index) => (
              <React.Fragment key={index}>
                {item.showHide && getComponentByName(item.component)}
              </React.Fragment>
            ))}
          <PaymentBlock />
          {showHideDownSalePopup && <DownSalePopup />}
          {showHideSpecialOfferPopup && <SpecialOfferPopup />}
        </ContainerComponent>
        <FooterContainer />
      </PageComponent>
    </TimerProvider>
  );
};

export default ABspecialOfferPlan;
