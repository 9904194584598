import React from "react";
import CustomProgresBar from "../../components/CustomProgresBar/CustomProgresBar";

const ProgressBarModule = ({ currentStep, steps }) => {
  return (
    <>
      {currentStep !== 0 &&
        steps.length &&
        steps[currentStep].showProgressBar && (
          <CustomProgresBar
            progress={((currentStep + 1) / steps.length) * 100}
          />
        )}
    </>
  );
};

export default ProgressBarModule;
