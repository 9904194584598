import React, { createContext, useContext, useState, useEffect } from "react";
import { usePageConfig } from "../../../context/PageConfigContext";

const QuizContext = createContext();

export const useQuizContext = () => useContext(QuizContext);

export const QuizContextProvider = ({ children }) => {
  const { findDataComponent } = usePageConfig();

  const data = findDataComponent("QuizLoader");

  const [speed] = useState(data.loaderSpeed);

  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress < 100) {
          return prevProgress + 1;
        } else {
          clearInterval(interval);
          return prevProgress;
        }
      });
    }, speed);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contextValue = {
    progress,
  };

  return (
    <QuizContext.Provider value={contextValue}>{children}</QuizContext.Provider>
  );
};
