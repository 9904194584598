import React, { useEffect, useState } from "react";

import { useTimer } from "../../../../context/TimerContext";
import styles from "./PageComponent.module.css";

const PageComponent = ({ children, bgColor }) => {
  const { showHideElement } = useTimer();

  const [paddingPx, setPaddingPx] = useState(50);

  useEffect(() => {
    if (showHideElement) {
      setPaddingPx(50);
    } else if (!showHideElement) {
      setPaddingPx(0);
    }
  }, [showHideElement]);

  return (
    <div
      className={styles.PageComponent}
      style={{
        paddingTop: `${paddingPx}px`,
        background: bgColor,
      }}
    >
      {children}
    </div>
  );
};
export default PageComponent;
