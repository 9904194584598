import React from "react";
import { useTimer } from "../../../../context/TimerContext";
import { usePageContent } from "../../../../context/PageContext";

import styles from "./SpecialOfferButtonStyle.module.css";

const SpecialOfferButton = () => {
  const { setShowHideSpecialOfferPopup, pageContent } = usePageContent();
  const { timer } = useTimer();

  const handleButtonClick = () => {
    setShowHideSpecialOfferPopup(true);
  };
  return (
    <div className={styles.btnContainer}>
      <button className={styles.paymentButton} onClick={handleButtonClick}>
        <span className={styles.paymentButtonText}>
          {pageContent.first_subscription_options
            .changing_button_text_after_timer_expires
            ? timer > 0
              ? pageContent.first_subscription_options.button_text[0]
              : pageContent.first_subscription_options.button_text[1]
            : pageContent.first_subscription_options.button_text[0]}
        </span>
        <span className={styles.arrow} />
      </button>
    </div>
  );
};

export default SpecialOfferButton;
