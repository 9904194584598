import styled from "styled-components";
import { mediaQueries } from "../../constants/breakpoints";
// import ReactPlayer from "react-player";

export const Page = styled.div`
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #3e3a54 0%, #120b3b 100%);
  max-height: fit-content;
  position: relative;
  top: 0;
  box-sizing: border-box;
  padding-top: 50px;
  overflow: scroll;
  font-size: 14px;
  @media ${mediaQueries.desktop} {
    font-size: 16px;
    max-height: fit-content;
  }
`;

export const Asset1 = styled.div`
  position: absolute;
  width: 562px;
  height: 762px;
  background: url("${process.env.REACT_APP_MEDIA_ENDPOINT +
    process.env.REACT_APP_TRECI_PACKAGE}/img/bg1.webp")
    no-repeat 0 0 / cover;
  opacity: 0.5;
  top: 0;
  right: 0;
  transform: matrix(1, 0, 0, -1, 0, 0);
  @media ${mediaQueries.desktop} {
    width: 841px;
    height: 1139px;
    top: 0;
    right: 0;
  }
`;

export const Asset2 = styled.div`
  position: absolute;
  width: 100%;
  height: 1200px;
  background: url("${process.env.REACT_APP_MEDIA_ENDPOINT +
    process.env.REACT_APP_TRECI_PACKAGE}/img/bg2.webp")
    no-repeat 0 0 / cover;
  opacity: 0.5;
  top: 1035px;
  left: 0;
  @media ${mediaQueries.desktop} {
    width: 1435px;
    height: 1749px;
    top: 864px;
    left: 0;
  }
`;
export const Asset3 = styled.div`
  position: absolute;
  width: 100%;
  height: 1200px;
  background: url("${process.env.REACT_APP_MEDIA_ENDPOINT +
    process.env.REACT_APP_TRECI_PACKAGE}/img/bg3.webp")
    no-repeat 0 0 / cover;
  opacity: 0.5;
  bottom: 319px;
  left: 0;
  @media ${mediaQueries.desktop} {
    width: 1156px;
    height: 1394px;
    bottom: 110px;
    left: 0;
  }
`;
export const Asset4 = styled.div`
  position: absolute;
  width: 600px;
  height: 700px;
  background: url("${process.env.REACT_APP_MEDIA_ENDPOINT +
    process.env.REACT_APP_TRECI_PACKAGE}/img/bg4.webp")
    no-repeat 0 0 / cover;
  opacity: 0.5;
  bottom: 0;
  right: 0;
  @media ${mediaQueries.desktop} {
    width: 1053px;
    height: 600px;
    bottom: 0px;
    right: 0;
  }
`;
export const Header = styled.div`
  width: 90%;
  margin: 24px auto 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${mediaQueries.desktop} {
    width: 80%;
    justify-content: space-between;
    margin: 16px auto 0;
  }
`;

export const Logo = styled.img`
  width: 120px;
  height: 40px;
  @media ${mediaQueries.desktop} {
    width: 180px;
    height: 58px;
  }
`;

export const Nav = styled.div`
  display: flex;
  align-items: center;
  display: none;
  a {
    text-decoration: none;
    color: #aeacbf;
    font-weight: 600;
    display: inline-block;
    margin-right: 40px;
    transition: color 0.2s;
    :hover {
      color: #fff;
    }
  }
`;

export const Container = styled.div`
  max-width: 736px;
  height: fit-content;
  margin: 0 auto;
  padding: 0 16px;
  // background-color:red;
  font-family: Inter, sans-serif;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  @media ${mediaQueries.desktop} {
    padding: 0 0 10px;
    height: fit-content;
  }
`;
export const Button = styled.a`
  display: flex;
  text-decoration: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  border-radius: 8px;
  color: #fff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  background: #1a4bff;
  opacity: 1;
  cursor: pointer;
  max-width: 100%;
  width: 100%;
  transition: opacity 0.2s;
  white-space: nowrap;
  :hover {
    opacity: 0.9;
  }
  ${(props) =>
    props.$wide &&
    `
        position: relative;
        img {
          position: absolute;
          right: 10px;
        }
      `}
  @media ${mediaQueries.desktop} {
    max-width: 300px;
    ${(props) =>
      props.$wide &&
      `
          max-width: 285px;
        `}
  }
  ${(props) =>
    props.$small &&
    `
        padding: 10px 16px;
        font-size: 14px;
        line-height: 16px;
      `}
  ${(props) =>
    props.$white &&
    `
        background: transparent;
        border: 1px solid #fff;
        color: #fff;
      `}
  ${(props) =>
    props.$desktop &&
    `
        display: none;
        @media ${mediaQueries.desktop} {
          display: flex;
        }
      `}
  ${(props) =>
    props.$mobile &&
    `
        min-width: 100%;
        @media ${mediaQueries.desktop} {
          display: none;
        }
      `}
`;

export const Hero = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  margin: 16px auto 0;
  align-items: center;
  justify-centent: space-between;
  padding: 0 16px;
  @media ${mediaQueries.desktop} {
    flex-direction: row;
    padding: 0;
    margin: 50px auto 0;
    gap: 20px;
  }
`;
export const HeroColumn = styled.div`
  flex: 0 0 48%;
  p {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin: 16px 0 0;
    @media ${mediaQueries.desktop} {
      text-align: left;
    }
  }
`;
export const HeroTitle = styled.div`
  margin-top: 24px;
  position: relative;
  margin-bottom: -11px;
  font-weight: 600;
  font-size: 21px;
  line-height: 28px;
  color: #fff;
  text-align: center;
  span {
    color: #4beeb3;
    font-size: 40px;
    font-weight: 700;
    @media ${mediaQueries.desktop} {
      font-size: 60px;
    }
  }
  @media ${mediaQueries.desktop} {
    text-align: left;
    font-size: 48px;
    line-height: 64px;
  }
`;
export const CountDown = styled.div`
  border: 1px solid #ff5858;
  border-radius: 12px;
  background: rgba(21, 19, 32, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  margin: 16px 16px 0;
  display: none;
  @media ${mediaQueries.desktop} {
    max-width: 400px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
`;
export const Count = styled.div`
  font-weight: 600;
`;
export const PlanContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const PlanImg = styled.img`
  margin-right: 12px;
  width: 96px;
  height: 96px;
`;
export const PlanData = styled.div`
  margin-right: 24px;
`;
export const PlanTitle = styled.p`
  font-size: 14px !important;
  line-height: 24px;
  padding: 0;
  margin: 0 !important;
  text-align: center !important;
  span {
    font-size: 21px !important;
    font-weight: 700;
  }
`;
export const PlanNote = styled.div`
  background: rgba(232, 237, 255, 0.06);
  padding: 6px 8px 6px;
  border-radius: 8px;
  p {
    text-align: left;
    margin: 0;
    font-size: 10px;
    line-height: 16px;
    margin-bottom: 4px;
    :last-child {
      margin-bottom: 0;
    }
    span {
      font-size: 24px;
      line-height: 16px;
      font-weight: 700;
    }
  }
`;
export const HeroSocial = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0 0;
  img {
    width: 56px;
    height: 24px;
    margin-right: 12px;
  }
  span {
    font-size: 10px;
    line-height: 14px;
  }
`;
export const PlanNotification = styled.div`
  padding: 4px 8px;
  background: rgba(199, 92, 60, 0.77);
  border-radius: 8px;
  font-size: 10px;
  line-height: 16px;
  margin-top: 10px;
`;
export const PlanPrice = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  margin-top: 8px;
  b {
    font-weight: 400;
    font-size: 14px;
  }
  ${(props) =>
    props.$previous &&
    `
      margin-top: 8px;
      color: #DE6641;
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
      strike {}
    `}
`;
export const Note = styled.div`
  display: inline-block;
  background: rgba(232, 237, 255, 0.06);
  border-radius: 8px;
  padding: 2px 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #aeacbf;
  align-self: flex-start;
  ${(props) =>
    props.$large &&
    `
      font-size: 14px;
    `}
`;
export const Moneyback = styled.div`
  background: rgba(75, 238, 179, 0.2)
    url("${process.env.REACT_APP_MEDIA_ENDPOINT +
    process.env.REACT_APP_TRECI_PACKAGE}/img/icon_shield.svg")
    no-repeat 4px 4px;
  border-radius: 8px;
  padding: 6px 10px 6px 26px;
  color: #4beeb3;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
`;
export const PlanText = styled.div`
  font-size: 14px;
  line-height: 24px;
`;
export const PaymentSystems = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  img {
    margin: 0 5px;
  }
`;
export const Safe = styled.div`
  background: rgba(232, 237, 255, 0.06)
    url("${process.env.REACT_APP_MEDIA_ENDPOINT +
    process.env.REACT_APP_TRECI_PACKAGE}/img/icon_lock2.svg")
    no-repeat 4px 1px;
  border-radius: 8px;
  padding: 4px 6px 4px 26px;
  color: #aeacbf;
  font-size: 12px;
  line-height: 16px;
`;
export const Limited = styled.div`
  background: rgba(232, 237, 255, 0.06);
  border-radius: 8px;
  padding: 2px 8px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  line-height: 16px;
  color: #ffd860;
  align-self: center;
  margin-top: 30px;
  margin-left: 50%;
  transform: translateX(-50%);
`;
export const Lifetime = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: center;
`;
export const LifetimeText = styled.div`
  font-size: 16px;
  line-height: 24px;
`;
export const LifetimeImg = styled.img`
  margin-right: 12px;
`;
export const LifetimeData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
export const LifetimeTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
`;
export const LifetimeProposal = styled.div`
  display: flex;
  align-items: center;
  span {
    background: rgba(255, 88, 88, 0.5);
    border-radius: 8px;
    padding: 4px 6px;
    margin-right: 8px;
  }
  strike {
    font-size: 14px;
    line-height: 16px;
  }
`;
export const LifetimePrice = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
`;
export const Celebrating = styled.div`
  margin-top: 40px;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #fff;
`;
export const ToggleButton = styled.button`
  border: none;
  background: transparent;
  font-weight: bold;
  color: #4beeb3;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
`;
export const AccordionItem = styled.div`
  background: rgba(232, 237, 255, 0.2);
  border-radius: 8px;
  padding: 8px 12px;
  width: 100%;
`;
export const AccordionTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-right: 30px;
  cursor: pointer;
  ${
    (props) =>
      props.$isOpen
        ? ""
        : //   `
          //   background: url('
          //   ${
          //     process.env.REACT_APP_MEDIA_ENDPOINT +
          //     process.env.REACT_APP_TRECI_PACKAGE
          //   }/img/icon_minus.svg
          //   ') no-repeat right 5px center;
          // `
          ""
    //   `
    //   background: url('${
    //     process.env.REACT_APP_MEDIA_ENDPOINT +
    //     process.env.REACT_APP_TRECI_PACKAGE
    //   }/img/icon_plus.svg') no-repeat right 5px center;
    // `
  }
`;
export const AccordionBody = styled.div`
  margin: 16px 0 8px;
  font-size: 16px;
  line-height: 20px;
  a {
    color: #999;
    :hover {
      text-decoration: none;
    }
  }
  ${(props) =>
    props.$isOpen
      ? `
      display: block;
    `
      : `
      display: none;
    `}
`;
export const EarlyAdopters = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-top: 30px;
`;
export const Component = styled.div`
  // background: rgba(21, 19, 32, 0.5);
  box-sizing: border-box;
  margin-bottom: 20px;
  height: fit-content;
  background: rgba(232, 237, 255, 0.2);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 16px;
  gap: 24px;
  margin-top: 30px;
  @media ${mediaQueries.desktop} {
    padding: 30px 24px;
  }
  ${(props) =>
    props.$light &&
    `
      background: linear-gradient(110.46deg, rgba(99, 191, 230, 0.5) 33.37%, rgba(145, 162, 255, 0.5) 63.44%);
    `}
  ${(props) =>
    props.$dark &&
    `
      background: rgba(21, 19, 32, 0.5);
    `}
  ${(props) =>
    props.$inner &&
    `
      width: 100%;
      gap: 16px;
      margin-top: 0;
      align-items: flex-start;
    `}
  ${(props) =>
    props.$thin &&
    `
      gap: 16px;
    `}
  ${(props) =>
    props.$plain &&
    `
      width: 100%;
      display: block;
      margin-top: 20px;
    `}
  .slick-slider {
    padding: 20px 0 0;
    @media ${mediaQueries.desktop} {
      padding: 0;
    }
  }
  .slick-list {
    margin: 0 -5px;
  }
  .slick-slide > div {
    padding: 0 5px;
  }
  .slick-dots {
    z-index: 0;
    width: 300px !important;
    bottom: 10px;
    ul {
      display: flex;
      justify-content: center;
    }
    li {
      button:before {
        color: #00558b;
      }
      &.slick-active {
        button:before {
          color: #fff;
        }
      }
    }
  }
  .slick-slide {
    > div {
      > div {
        height: 388px;
        margin-top: 0;
        margin-bottom: 30px;
        @media ${mediaQueries.desktop} {
          height: 300px;
        }
      }
    }
  }
`;
export const ComponentTitle = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  span {
    color: #4beeb3;
  }
  ${(props) =>
    props.$custom &&
    `
      font-size: 24px;
      line-height: 32px;
      span {
        font-size: 40px;
      }
    `}
  ${(props) =>
    props.$icon &&
    `
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
      img {
        margin-right: 16px;
      }
      span {
        color: #fff;
      }
    `}
  ${(props) =>
    props.$center &&
    `
      text-align: center;
    `}
  ${(props) =>
    props.$leftAligned &&
    `
      text-align: left;
      line-height: 24px;
      font-size: 16px;
      width: 100%;
      span.large {
        color: #fff;
        font-size: 24px;
        text-transform: uppercase;
      }
      @media ${mediaQueries.desktop} {
        text-align: center;
        line-height: 32px;
        font-size: 24px;
        span.large {
          font-size: 32px;
          text-transform: uppercase;
        }
      }
    `}
`;
export const ComponentText = styled.div`
  font-size: 14px;
  line-height: 20px;
  ${(props) =>
    props.$offsetLeft &&
    `
      margin-left: 65px;
    `}
  p {
    margin: 0 0 8px;
  }
`;
// export const StyledReactPlayer = styled(ReactPlayer)`
//   width: 688px;
//   height: 320px;
//   border-radius: 16px;
//   overflow: hidden;
//   .react-player__play-icon {
//     background: url("
// ") no-repeat 0 0;
//     width: 64px;
//     height: 64px;
//     border: none !important;
//     margin-left: 0 !important;
//   }
// `;
export const Prizes = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: space-around;
  img {
    max-width: 150px;
  }
  @media ${mediaQueries.desktop} {
    gap: 28px;
  }
`;
export const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 100%;
  div {
    display: flex;
    align-items: center;
    &.highlight {
      background: rgba(232, 237, 255, 0.2);
      border-radius: 12px;
      padding: 10px 10px 10px 0;
      margin-top: 8px;
      > img {
        margin-left: 5px;
        margin-right: 5px;
      }
      span {
        display: flex;
        flex-direction: column;
        img {
          width: 180px;
          margin-bottom: 10px;
        }
      }
    }
  }
  img {
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }
  i {
    font-size: 24px;
    font-style: normal;
    width: 35px;
  }
  ${(props) =>
    props.$thin &&
    `
      img {
        margin-right: 12px;
      }
    `}
  ${(props) =>
    props.$offsetLeft &&
    `
      width: calc(100% - 60px);
    `}
  ${(props) =>
    props.$center &&
    `
      @media ${mediaQueries.desktop} {
        padding-left: 20%;
      }
    `}
`;
export const Tags = styled.div`
  width: 100%;
`;
export const TagsTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
`;
export const TagsInner = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const Tag = styled.div`
  background: rgba(232, 237, 255, 0.2);
  border-radius: 12px;
  padding: 4px 12px;
  font-size: 16px;
  line-height: 24px;
  margin: 8px 8px 0 0;
`;

export const Rating = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0;
  img {
    margin-right: 4px;
    width: 14px;
    height: 13px;
    &.part {
      width: 25px;
      height: 25px;
    }
  }
`;
export const Feedback = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin-top: 12px;
`;
export const Viewing = styled.div`
  background: rgba(232, 237, 255, 0.06);
  border-radius: 8px;
  padding: 5px 10px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  margin-top: 16px;
  justify-content: center;
  font-size: 12px;
  color: #6d8dff;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;
export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  flex-direction: column-reverse;
  gap: 10px;
  @media ${mediaQueries.desktop} {
    flex-direction: row;
    gap: 0;
    justify-content: space-between;
  }
`;
export const Copy = styled.div`
  font-size: 12px;
  line-height: 16px;
`;
export const FooterNav = styled.div`
  a {
    color: #fff;
    margin-left: 16px;
    text-decoration: none;
    font-size: 12px;
    line-height: 16px;
  }
`;

export const Average = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;
  ${Rating} {
    img {
      margin-right: 5px;
    }
    justify-content: center;
  }
`;
export const AverageItem = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background: rgba(21, 19, 32, 0.5);
  border-radius: 16px;
  padding: 16px 12px;
  gap: 8px;
`;
export const AverageData = styled.div`
  display: flex;
  img {
    margin-right: 16px;
  }
  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    margin: 0;
  }
  p {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  justify-content: center;
`;
export const StickyButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 0 16px;
  margin-top: 32px;
  position: fixed;
  bottom: -150px;
  z-index: 0;
  padding: 16px 16px 24px;
  background: linear-gradient(180deg, #3e3a54 0%, #120b3b 100%);
  border-radius: 16px 16px 0px 0px;
  transition: bottom 0.2s;
  a {
    max-width: 100%;
  }
  ${(props) =>
    props.$isSticky === true
      ? `
        bottom: 0;
      `
      : ``}
  @media ${mediaQueries.desktop} {
    max-width: 736px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const PaymentPopup = styled.div`
  margin: 0 auto;
  margin-top: 10%;
  // position: fixed;
  // width: 90%;
  // height: 100%;
  background-color: #fff;
  border-radius: 20px;
  padding: 30px 20px 20px;
  max-width: 700px;
  max-height: 100vh;
  // height: auto;
  // top: 50%;
  // left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 3;
  iframe {
    width: 100%;
  }
  @media ${mediaQueries.desktop} {
    width: 100%;
  }
`;

export const Shadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  background: rgba(32, 19, 71, 0.8);
`;

export const PaymentClose = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  right: 0;
  background: url("${process.env.REACT_APP_MEDIA_ENDPOINT +
    process.env.REACT_APP_TRECI_PACKAGE}/img/icon_cross.svg")
    no-repeat center center / cover;
  cursor: pointer;
  z-index: 0;
`;

export const PaymentNote = styled.div`
  font-size: 12px;
  color: #999;
  margin: 16px 0;
  text-align: center;
`;

export const Loader = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba(32, 19, 71, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 100px;
    height: 100px;
  }
`;

export const PaymentLifetime = styled.div`
  display: flex;
  align-items: center;
  width: 95%;
  border-radius: 16px;
  border: 1px solid #e8edff;
  padding: 8px 4px 8px 14px;
  color: #151320;
`;
export const PaymentLifetimeImg = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 12px;
`;
export const PaymentLifetimeData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
export const PaymentLifetimeTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
`;
export const PaymentLifetimePrice = styled.div`
  font-size: 24px;
  line-height: 24px;
  font-size: 11px;
  b {
    font-size: 10px;
  }
`;
export const PaymentOr = styled.div`
  font-size: 11px;
  line-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aeacbf;
  :before {
    content: "";
    height: 1px;
    position: absolute;
    background-color: #e8edff;
    width: 100%;
    top: 8px;
  }
  span {
    background-color: #fff;
    padding: 0 8px;
    position: relative;
    z-idnex: 2;
  }
`;

export const PaymentGuarantee = styled.div`
  font-size: 10px;
  line-height: 16px;
  font-weight: 700;
  color: #196c89;
  height: 28px;
  flex: 0 0 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(75, 238, 179, 0.2);
  border-radius: 8px;
  img {
    margin-right: 4px;
  }
`;

export const NewsContainer = styled.div``;
export const NewsTitle = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 11px;
  line-height: 22px;
  color: rgba(194, 194, 195, 0.5);
  margin-bottom: 12px;
  @media ${mediaQueries.desktop} {
    font-size: 14px;
  }
`;
export const News = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  img {
    :nth-child(1) {
      width: 38%;
    }
    :nth-child(2) {
      width: 15%;
      height: 15px;
      @media ${mediaQueries.desktop} {
        height: 31px;
      }
    }
    :nth-child(3) {
      width: 38%;
    }
    :nth-child(4) {
      width: 40%;
    }
    :nth-child(5) {
      width: 40%;
    }
  }
  @media ${mediaQueries.desktop} {
    gap: 10px;
    img {
      width: 20%;
    }
  }
`;
