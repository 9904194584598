import React from "react";

import { usePageConfig } from "../../../../context/PageConfigContext";

import styles from "./JoinTheRestStyle.module.css";

const JoinTheRest = () => {
  const { findDataComponent } = usePageConfig();

  const data = findDataComponent("JoinTheRest");

  return (
    <div className={styles.container}>
      <div className={styles.imgPeople} />
      <span className={styles.bigText}>{data.mainText}</span>
      <span className={styles.smallText}>{data.lowText}</span>
    </div>
  );
};

export default JoinTheRest;
