import React from "react";

import styles from "./FooterTextContainerStyle.module.css";

const FooterTextContainerTwo = ({ renderData }) => {
  return (
    <div className={styles.Footer}>
      <span className={styles.FooterText}>{renderData}</span>
    </div>
  );
};

export default FooterTextContainerTwo;
