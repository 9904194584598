import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useNavigationPath } from "../../../../hooks/navigationPath";
import { preserveUrlParams } from "../../../../utils/urlParams";

import { useRemoteConfig } from "../../../../context/RemoteConfigContext";
import { usePageConfig } from "../../../../context/PageConfigContext";
import { useEvent } from "../../../../context/EventContext";
import { usePaymentBlock } from "../../../../context/PaymentBlockContext";

import EmailContainer from "./EmailContainer/EmailContainer";
import UserNameContainer from "./UserNameContainer/UserNameContainer";

import styles from "./UserNameEmailStepStyle.module.css";

const UserNameEmailStep = () => {
  const { setStartNameEmailForm } = usePaymentBlock();

  const { analyticsEvent, setSnaptrUserData } = useEvent();
  const { sendQuizRequest } = usePageConfig();

  const navigate = useNavigate();

  const reservePath = useNavigationPath("reserve");

  const { waitlistPageData } = useRemoteConfig();

  const [data] = useState(waitlistPageData.lead_form);

  const [validUserData, setValidUserData] = useState([false, false]);

  const updateValidUserDataAtIndex = (index, value) => {
    setValidUserData((prevValidUserData) => {
      const newValidUserData = [...prevValidUserData];
      newValidUserData[index] = value;
      return newValidUserData;
    });
  };

  const [userData, setUserData] = useState({});

  const updateUserData = (newData) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      ...newData,
    }));
  };

  const handleSubmit = () => {
    if (validUserData.every((value) => value === true)) {
      const gtagEventData = {
        ...(userData.userName && { event_user_name: userData.userName }),
        ...(userData.userEmail && { event_user_email: userData.userEmail }),
      };

      if (Object.keys(gtagEventData).length > 0) {
        analyticsEvent("leadFormSubmit", gtagEventData);
        analyticsEvent("conversion");
      }

      localStorage.setItem("MemoryOSUserData", JSON.stringify(userData));
      setSnaptrUserData(userData);
      analyticsEvent("completeRegistration");
      const findQuizData = localStorage.getItem("MemoryOSQuizData");
      if (findQuizData !== null) {
        sendQuizRequest();
      }
    } else {
      return false;
    }
    setStartNameEmailForm(false);

    navigate(preserveUrlParams(reservePath));
  };
  return (
    <div className={styles.ShadowPopup}>
      <div className={styles.containerUserNameEmailStep}>
        <button
          className={styles.closeButton}
          onClick={() => {
            setStartNameEmailForm(false);
          }}
        >
          X
        </button>
        <div className={styles.titleContainer}>
          <span className={styles.titleText}>{data.title}</span>
        </div>
        <UserNameContainer
          placeholderInfo={data.user_name_placeholder}
          updateUserData={updateUserData}
          updateValidUserDataAtIndex={updateValidUserDataAtIndex}
        />
        <EmailContainer
          placeholderInfo={data.user_email_placeholder}
          invalidEmail={data.invalid_email_placeholder}
          updateUserData={updateUserData}
          updateValidUserDataAtIndex={updateValidUserDataAtIndex}
        />

        <button
          className={
            validUserData.every((value) => value === true)
              ? styles.buttonStyle
              : styles.buttonStyleDisable
          }
          onClick={() => {
            handleSubmit();
          }}
        >
          {data.button_text}
        </button>
        <div className={styles.noteContainer}>
          <span className={styles.noteText}>{data.footer_text}</span>{" "}
          <a
            href={data.terms_conditions.link}
            rel="noreferrer"
            target="_blank"
            className={styles.link}
          >
            {data.terms_conditions.text}
          </a>{" "}
          <span className={styles.noteText}>&</span>{" "}
          <a
            href={data.privacy_policy.link}
            rel="noreferrer"
            target="_blank"
            className={styles.link}
          >
            {data.privacy_policy.text}
          </a>
        </div>
      </div>
    </div>
  );
};

export default UserNameEmailStep;
