import React, { useEffect, useState } from "react";
import { useRemoteConfig } from "../../../../context/RemoteConfigContext";

import styles from "./TimerComponentStyle.module.css";

const TimerComponent = () => {
  const { reservePageData } = useRemoteConfig();

  const [data] = useState(reservePageData.top_component.bottom_timer_text);

  const [targetDate] = useState(reservePageData.timer_date)

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const [day, month, year] = targetDate.split(".");
      const target = new Date(year, month - 1, day);
      const now = new Date();
      const difference = target.getTime() - now.getTime();

      if (difference <= 0) {
        return {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
      }

      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    };

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    setTimeLeft(calculateTimeLeft());

    return () => clearInterval(timer);
  }, [targetDate]);

  const formatTimeLeft = ({ days, hours, minutes, seconds }) => {
    const timeUnits = [
      { value: days, label: "d" },
      { value: hours, label: "h" },
      { value: minutes, label: "m" },
      { value: seconds, label: "s" },
    ].map(({ value, label }) => `${String(value).padStart(2, "0")}${label}`);

    return timeUnits.join(" ");
  };

  return (
    <div className={styles.container}>
      <span className={styles.timerText}>{formatTimeLeft(timeLeft)}</span>
      <span className={styles.bottomText}>{data}</span>
    </div>
  );
};

export default TimerComponent;
