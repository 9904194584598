import React, { useState, useEffect } from "react";

// import { useRemoteConfig } from "../../../../context/FirebaseConfigContext";
// import { getData } from "../../../../utils/fetch";

import { usePageConfig } from "../../../../context/PageConfigContext";

import styles from "./ViewingPageCounterStyle.module.css"

const ViewingPageCounter = () => {
  // const { appData } = useRemoteConfig();
  const [views, setViews] = useState(0);

  const { findDataComponent } = usePageConfig();

  const data = findDataComponent("ViewingPageCounter");

  useEffect(() => {
    setViews(Math.floor(Math.random() * 57) + 5);
  }, []);

  // useEffect(() => {
  //   getData(`${appData.REACT_APP_DATA_ENDPOINT}/api/watching/`).then(
  //     (response) => {
  //       setViews(response.watching);
  //     }
  //   );
  // }, []);

  return (
    <div className={styles.container}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M12 9.75C10.7574 9.75 9.75 10.7574 9.75 12C9.75 13.2426 10.7574 14.25 12 14.25C13.2426 14.25 14.25 13.2426 14.25 12C14.25 10.7574 13.2426 9.75 12 9.75Z"
          fill="#6D8DFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 5.5C9.38223 5.5 7.02801 6.55139 5.33162 7.85335C4.48232 8.50519 3.78544 9.22913 3.29649 9.93368C2.81686 10.6248 2.5 11.3515 2.5 12C2.5 12.6485 2.81686 13.3752 3.29649 14.0663C3.78544 14.7709 4.48232 15.4948 5.33162 16.1466C7.02801 17.4486 9.38223 18.5 12 18.5C14.6178 18.5 16.972 17.4486 18.6684 16.1466C19.5177 15.4948 20.2146 14.7709 20.7035 14.0663C21.1831 13.3752 21.5 12.6485 21.5 12C21.5 11.3515 21.1831 10.6248 20.7035 9.93368C20.2146 9.22913 19.5177 8.50519 18.6684 7.85335C16.972 6.55139 14.6178 5.5 12 5.5ZM8.25 12C8.25 9.92893 9.92893 8.25 12 8.25C14.0711 8.25 15.75 9.92893 15.75 12C15.75 14.0711 14.0711 15.75 12 15.75C9.92893 15.75 8.25 14.0711 8.25 12Z"
          fill="#6D8DFF"
        />
      </svg>
      <span className={styles.text}>
        {views} {data.text}
      </span>
    </div>
  );
};

export default ViewingPageCounter;
