import React from "react";
import { useTimer } from "../../../../context/TimerContext";
import { useButtonRef } from "../../../../context/ButtonRefContext";
import { usePageContent } from "../../../../context/PageContext";
import { useEvent } from "../../../../context/EventContext";

import styles from "./PaymentButtonStyle.module.css";

const PaymentButton = () => {
  const { timer } = useTimer();

  const { paymentButtonRef } = useButtonRef();

  const { analyticsEvent } = useEvent();

  const { pageContent } = usePageContent();

  const handleButtonClick = () => {
    if (paymentButtonRef.current) {
      paymentButtonRef.current.click();
      analyticsEvent("initiateCheckout");
    }
  };

  return (
    <div className={styles.btnContainer}>
      <button className={styles.paymentButton} onClick={handleButtonClick}>
        <span className={styles.paymentButtonText}>
          {pageContent.first_subscription_options
            .changing_button_text_after_timer_expires
            ? timer > 0
              ? pageContent.first_subscription_options.button_text[0]
              : pageContent.first_subscription_options.button_text[1]
            : pageContent.first_subscription_options.button_text[0]}
        </span>
        <span className={styles.arrow} />
      </button>
    </div>
  );
};

export default PaymentButton;
