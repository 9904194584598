import React, { useEffect } from "react";
import { useTimer } from "../../../../../context/TimerContext";

import { usePageContent } from "../../../../../context/PageContext";

import styles from "./ToggleButton.module.css";

const ToggleButton = () => {
  const { timer } = useTimer();

  const { toggleStatus, setToggleStatus, pageContent, toogleProcessing } =
    usePageContent();

  useEffect(() => {
    if (timer <= 0) {
      setToggleStatus(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  return (
    <div className={styles.toggleButton}>
      {toggleStatus ? (
        <div className={styles.toggleButtonEnabledTrialText}>
          <span className={styles.toggleButtonTextTop}>
            {pageContent.toogle_button_text.toggle_enable}
          </span>
        </div>
      ) : (
        <span className={styles.toggleButtonText}>
          {pageContent.toogle_button_text.toggle_disable}
        </span>
      )}

      <div
        className={`${styles.toggleButtonInner} ${
          toggleStatus ? styles.active : ""
        }`}
        onClick={() => toogleProcessing()}
      >
        <div className={styles.toggleButtonSwitch}></div>
      </div>
    </div>
  );
};

export default ToggleButton;
