import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useNavigationPath } from "../../../hooks/navigationPath";
import { useResizeContainer } from "../../../hooks/resizeContainer";
import { preserveUrlParams } from "../../../utils/urlParams";

import { usePageConfig } from "../../../context/PageConfigContext";
import { useRemoteConfig } from "../../../context/RemoteConfigContext";
import { useButtonRef } from "../../../context/ButtonRefContext";

import YouTubePlayer from "../../templates/YoutubePlayer/YoutubePlayer";

// import FeatureSlider from "./FeatureSlider/FeatureSlider";
import NewsContainer from "./NewsContainer/NewsContainer";

import styles from "./YourGrandmaster.module.css";

const YourGrandmaster = () => {
  const { findDataComponent } = usePageConfig();
  const { paymentButtonRef } = useButtonRef();
  const { waitlistPageData } = useRemoteConfig();

  const containerRef = useRef(null);

  const { widthCont, heightCont, radiusCont } =
    useResizeContainer(containerRef);

  const data = findDataComponent("YourGrandmaster");

  const [buttonText] = useState(waitlistPageData.grandmaster_button_text);

  const navigate = useNavigate();

  const reservePath = useNavigationPath("reserve");

  const handleButtonClick = () => {
    const userData = JSON.parse(localStorage.getItem("MemoryOSUserData"));

    if (userData) {
      navigate(preserveUrlParams(reservePath));
    } else if (!userData && paymentButtonRef.current) {
      paymentButtonRef.current.click();
    }
  };

  return (
    <div className={styles.YourGrandmasterContainer}>
      <button onClick={handleButtonClick} className={styles.button}>
        <span className={styles.buttonText}>{buttonText}</span>
      </button>
      <span className={styles.headerText}>{data.headerText}</span>
      <span className={styles.mainText}>{data.mainText}</span>
      <span className={styles.mainText}>{data.footerText}</span>
      <div className={styles.awardsContainer}>
        {data.awards.map((element, index) => {
          return (
            <div key={index} className={styles.awardElement}>
              <img
                src="/img/Layer_cup.png"
                alt="Jonas achievement"
                className={styles.layerCup}
              />
              <span className={styles.awardText}>{element}</span>
            </div>
          );
        })}
      </div>
      <div className={styles.sliderPosition}>
        <NewsContainer featuredText={data.featuredText} />
        {/* <FeatureSlider /> */}
      </div>
      <div className={styles.videoContainer} ref={containerRef}>
        <YouTubePlayer
          videoId={data.youtube_video_id}
          width={widthCont}
          height={heightCont}
          borderRadius={radiusCont}
        />
      </div>
      <div className={styles.backgroundImg} />
    </div>
  );
};

export default YourGrandmaster;
