import React, { useRef, useState } from "react";

import { useResizeContainer } from "../../../../hooks/resizeContainer";

import { useRemoteConfig } from "../../../../context/RemoteConfigContext";
import { usePageContent } from "../../../../context/PageContext";

import YouTubePlayer from "../../../templates/YoutubePlayer/YoutubePlayer";

import styles from "./JonasComponentStyle.module.css";

const JonasComponent = () => {
  const { reservePageData } = useRemoteConfig();
  const { downSaleStatus } = usePageContent();

  const containerRef = useRef(null);

  const { widthCont, heightCont, radiusCont } =
    useResizeContainer(containerRef);

  const [data] = useState(reservePageData.jonas_component);

  return (
    <div className={styles.container}>
      <span className={styles.title}>{data.title}</span>
      <span className={styles.subTitle}>
        {!downSaleStatus ? data.sub_title[0] : data.sub_title[1]}
      </span>
      <div className={styles.videoContainer} ref={containerRef}>
        <YouTubePlayer
          videoId={data.youtube_video_id}
          width={widthCont}
          height={heightCont}
          borderRadius={radiusCont}
        />
      </div>
    </div>
  );
};

export default JonasComponent;
