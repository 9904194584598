import React, { useRef, useState } from "react";
// import ReactPlayer from "react-player";

import styles from "./VideoPlayerStyle.module.css";

const VideoPlayer = ({ videoId, thumbnail }) => {
  const playerRef = useRef(null);
  const [playVideo, setPlayVideo] = useState(false);
  const loadVimeoVideo = () => {
    setPlayVideo(true);
  };

  return (
    <div className={styles.ReactPlayer}>
      {/* <ReactPlayer
        id={`player-${videoId}`}
        ref={playerRef}
        light={thumbnail || ""}
        url={`https://vimeo.com/${videoId}`}
        controls={true}
        playing={playVideo}
        width={"100%"}
        height={220}
        style={{
          margin: "0 auto 10px",
          borderRadius: "16px",
          overflow: "hidden",
          display: "block",
          cursor: "pointer",
        }}
        onClick={loadVimeoVideo}
      /> */}
    </div>
  );
};

export default VideoPlayer;
