import React from "react";
import { usePageConfig } from "../../../context/PageConfigContext";

import styles from "./InfoImgBlockStyle.module.css";

const InfoImgBlock = () => {
  const { findDataComponent } = usePageConfig();

  const data = findDataComponent("InfoImgBlock");

  return (
    <div className={styles.container}>
      {data.blockText.map((elem, index) => {
        return (
          <div key={index} className={styles.elementCont}>
            <img
              src={`/img/InfoImgBlock_frame_${index}.png`}
              alt={`InfoImgBlock ${index}`}
              // className={`${styles.img} ${styles['img' + index]}`}
              className={styles.img}
            />
            <div className={styles.textContainer}>
              <span className={styles.title}>{elem.title}</span>
              <span className={styles.main}>{elem.main}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default InfoImgBlock;
