import React, { useState } from "react";
import MarketButtons from "./MarketButtons/MarketButtons";

import styles from "./BottomComponentStyle.module.css";

const BottomComponent = ({ dataComponent }) => {
  const [text] = useState(["130+ Gamified", " Micro-Lessons"]);

  return (
    <div className={styles.container}>
      <div className={styles.imgTextContainer}>
        <img
          src="/img/iphone_shadow.png"
          alt="iphoneShadow"
          className={styles.iphoneShadow}
        />
        <img
          src="/img/iphone_full.png"
          alt="Bottom img thankyou page"
          className={styles.iphoneFull}
        />
        <div className={styles.gamepadContainer}>
          <img
            src="/img/gamepad.png"
            alt="Gamepad"
            className={styles.gamepadImg}
          />
          <div>
            <span className={styles.gameText}>{text[0]}</span>
            <br />
            <span className={styles.gameText}>{text[1]}</span>
          </div>
        </div>
      </div>
      <div className={styles.buttonQRContainer}>
        <p className={styles.headerBtnText}>{dataComponent.headerBtnTextHP}</p>
        <p className={styles.headerQRBtnText}>{dataComponent.scanQR_TextHP}</p>
        <div className={styles.buttonQR}>
          <img
            src="/img/qr-frame-ty.png"
            alt="QR-memoryOS"
            className={styles.qrImg}
          />
          <MarketButtons />
        </div>
      </div>
    </div>
  );
};

export default BottomComponent;
