import React from "react";
import { usePromoCounter } from "../../../../../../context/PromoIncrementCounerContext";

import styles from "./TopBlockStyle.module.css";

const TopBlock = ({ data }) => {
  const { counter } = usePromoCounter();

  return (
    <div className={styles.topBlock}>
      <span className={styles.topBlockTitle}>{data.title}</span>
      <div className={styles.topBlockMain}>
        <div className={styles.topBlockParts}>
          <span className={styles.topBlockPartsTitle}>
            {data.waitlist.title}
          </span>
          <div className={styles.topBlockLeftPartImgCont}>
            <img
              src="../img/newReservePagePeopleFrame.png"
              alt="WaitList"
              className={styles.topBlockLeftPartImg}
            />
            <span className={styles.topBlockLeftPartText}>{counter}</span>
          </div>
        </div>
        <div
          className={styles.topBlockParts}
          style={{
            marginLeft: "15px",
          }}
        >
          <span className={styles.topBlockPartsTitle}>
            {data.lifetime_accounts.title}
          </span>
          <span className={styles.topBlockLeftPartText}>
            {data.lifetime_accounts.count}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TopBlock;
