import React, { useState } from "react";
import styles from "./PageComponent.module.css";

const PageComponent = ({ children, bgColor }) => {
  const [paddingPx] = useState(50);

  return (
    <div
      className={styles.PageComponent}
      style={{
        paddingTop: `${paddingPx}px`,
        background: bgColor,
      }}
    >
      {children}
    </div>
  );
};
export default PageComponent;
