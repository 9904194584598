import React from "react";
import { usePageContent } from "../../../../context/PageContext";

import styles from "../TopComponent/TopComponentStyle.module.css";

const TopComponentVariantB = ({
  userName,
  dataComponent,
  planText,
  userDiscountStatus,
}) => {
  const { renewalDate } = usePageContent();

  return (
    <>
      {!userDiscountStatus && (
        <div className={styles.container}>
          <div className={styles.welcomeUserText}>
            <span className={styles.userNameText}>{userName}</span>
            <span>{dataComponent.welcomeText}</span>
          </div>
          <div className={styles.subscContainer}>
            <span className={styles.subscContainerText}>
              {dataComponent.subscText}
            </span>
            <span className={styles.subscContPlanText}>{planText}</span>
            {renewalDate && (
              <span className={styles.subscContExpText}>
                Renewal: {renewalDate}
              </span>
            )}
          </div>
        </div>
      )}
      {userDiscountStatus && (
        <div className={styles.cancelDiscountTitleContainer}>
          {userName !== "" ? (
            <span className={styles.specialText}>
              {userName}
              {dataComponent.holiday_promo.notify_title.with_username}{" "}
              <span className={styles.regularText}>
                {dataComponent.holiday_promo.notify_title.notify_text}
              </span>
            </span>
          ) : (
            <span className={styles.specialText}>
              {dataComponent.holiday_promo.notify_title.without_username}{" "}
              <span className={styles.regularText}>
                {dataComponent.holiday_promo.notify_title.notify_text}
              </span>
            </span>
          )}
        </div>
      )}
    </>
  );
};

export default TopComponentVariantB;
