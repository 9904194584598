import React, { useState } from "react";
import { useButtonRef } from "../../../../context/ButtonRefContext";
import { usePageContent } from "../../../../context/PageContext";
import { usePromoCounter } from "../../../../context/PromoIncrementCounerContext";

import MainButton from "../MainButton/MainButton";

import styles from "./TopInfoStyle.module.css";

const TopInfo = () => {
  const { pageContent, downSaleStatus } = usePageContent();

  const { paymentButtonRef } = useButtonRef();
  const { counter } = usePromoCounter();

  const [data] = useState(pageContent.top_info);


  const handleButtonClick = () => {
    if (paymentButtonRef.current) {
      paymentButtonRef.current.click();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerButton} onClick={handleButtonClick}>
        <span className={styles.headerBtnText}>{data.header_btn}</span>
      </div>
      <span className={styles.header}>{data.header}</span>
      <div className={styles.containerStatistic}>
        <div className={styles.infoContainer}>
          <span className={styles.topText}>{data.accounts.quantity}</span>
          <span className={styles.mainTextInfo}>
            {data.accounts.accounts_text}
          </span>
        </div>
        <div className={styles.infoContainer}>
          {/* <span className={styles.topText}>{dataWaitlist}</span> */}
          <span className={styles.topText}>{counter}</span>
          <span
            className={styles.mainTextInfo}
            style={{
              width: "200px",
            }}
          >
            {data.people_waitlist}
          </span>
        </div>
      </div>
      <div className={styles.mainTextContainer}>
        {!downSaleStatus && (
          <span className={styles.mainText}>{data.main_text[0]}</span>
        )}
        {downSaleStatus && (
          <span className={styles.mainText}>{data.main_text[1]}</span>
        )}
      </div>

      <div
        style={{
          marginTop: "50px",
        }}
      >
        <MainButton
          btnText={!downSaleStatus ? data.button_text[0] : data.button_text[1]}
          undBtnText={data.under_btn_text}
        />
      </div>
      {!downSaleStatus && (
        <span className={styles.footerText}>{data.footer_text}</span>
      )}

      {downSaleStatus && (
        <span className={styles.bottomText}>
          {data.discount_footer_text.map((text, index) => (
            <React.Fragment key={index}>
              {index === 1 || index === 3 ? (
                <span className={styles.bottomTextSpecial}>{text}</span>
              ) : (
                text
              )}{" "}
            </React.Fragment>
          ))}
        </span>
      )}
    </div>
  );
};

export default TopInfo;
