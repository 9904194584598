import React from "react";
import { Step, Column, Title, Answers, Answer } from "../../styles";
import { useAnalyticsUserData } from "../../../../context/AnalyticsUserData";

const GenderStep = ({ id, step, handleNextStep }) => {
  const { updUserData } = useAnalyticsUserData();

  const handleGenderSelection = (answer, index) => {
    updUserData("user_gender", answer);

    handleNextStep({ id, answer, answerID: index, type: "gender" });
  };
  return (
    <Step>
      <Column>
        <Title>{step.title}</Title>
      </Column>
      <Column>
        <Answers $hideOnMobile={step.subtype && step.subtype === "rate"}>
          {step.answers.map((answer, index) => (
            <Answer
              key={`${id}-${index}`}
              onClick={() => handleGenderSelection(answer, index)}
            >
              {answer}
            </Answer>
          ))}
        </Answers>
      </Column>
    </Step>
  );
};

export default GenderStep;
