import React, { useState, useEffect } from "react";
import { usePageContent } from "../../../../context/PageContext";
import { useButtonRef } from "../../../../context/ButtonRefContext";

import LogoHP from "../../../templates/LogoHP/LogoHP";

import styles from "./HeaderStyle.module.css";

const Header = () => {
  const { pageContent } = usePageContent();
  const [data] = useState(pageContent.header);

  const [targetDate] = useState(data.timer_date);

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const [day, month, year] = targetDate.split(".");
      const target = new Date(year, month - 1, day);
      const now = new Date();
      const difference = target.getTime() - now.getTime();

      if (difference <= 0) {
        return {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
      }

      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    };

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    setTimeLeft(calculateTimeLeft());

    return () => clearInterval(timer);
  }, [targetDate]);

  const formatTimeLeft = ({ days, hours, minutes, seconds }) => {
    const timeUnits = [
      { value: days, label: "d" },
      { value: hours, label: "h" },
      { value: minutes, label: "m" },
      { value: seconds, label: "s" },
    ].map(({ value, label }) => `${String(value).padStart(2, "0")}${label}`);

    return timeUnits.join(" ");
  };

  const { paymentButtonRef } = useButtonRef();

  const handleButtonClick = () => {
    if (paymentButtonRef.current) {
      paymentButtonRef.current.click();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <LogoHP />
        <button className={styles.button} onClick={handleButtonClick}>
          <span className={styles.buttonText}>{data.button_text}</span>
        </button>
      </div>
      <div className={styles.infoContainer}>
        <span className={styles.topText}>{formatTimeLeft(timeLeft)}</span>
        <span className={styles.mainText}>{data.under_timer_text}</span>
      </div>
    </div>
  );
};

export default Header;
