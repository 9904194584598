import React, { useEffect, useState } from "react";
import { useTimer } from "../../../../context/TimerContext";

import InfoTitle from "../InfoTitle/InfoTitle";
import ImgModule from "../ImgModule/ImgModule";

import styles from "./TopDoubleComponentStyle.module.css";

const TopDoubleComponent = () => {
  const { showHideElement } = useTimer();

  const [marTopm, setMarTop] = useState("40px");

  useEffect(() => {
    if (!showHideElement) {
      setMarTop("10px");
    } else {
      setMarTop("40px");
    }
  }, [showHideElement]);

  return (
    <div
      className={styles.doubleComponentStyle}
      style={{
        marginTop: marTopm,
      }}
    >
      <InfoTitle />
      <ImgModule />
    </div>
  );
};

export default TopDoubleComponent;
