import React, { useState } from "react";

import { useRemoteConfig } from "../../../../context/RemoteConfigContext";

import LeftSection from "./LeftSection/LeftSection";
import RightSection from "./RightSection/RightSection";

import styles from "./BottomComponentStyle.module.css";

const BottomComponent = () => {
  const { reservePageData } = useRemoteConfig();

  const [data] = useState(reservePageData.bottom_component);

  return (
    <div className={styles.container}>
      <LeftSection data={data.left_section} />
      <RightSection data={data.right_section} />
    </div>
  );
};

export default BottomComponent;
