import React, { createContext, useState, useContext, useEffect } from "react";
import CryptoJS from "crypto-js";

const AnalyticsUserDataContext = createContext();

export const useAnalyticsUserData = () => useContext(AnalyticsUserDataContext);

export const AnalyticsUserDataProvider = ({ children }) => {
  const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

  const [analyticsUserData, setAnalyticsUserData] = useState({});

  const encryptData = (data) => {
    const jsonString = JSON.stringify(data);
    const encrypted = CryptoJS.AES.encrypt(jsonString, SECRET_KEY).toString();
    return encrypted;
  };

  const decryptData = (encryptedData) => {
    try {
      const decrypted = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
      const jsonString = decrypted.toString(CryptoJS.enc.Utf8);
      return JSON.parse(jsonString);
    } catch (error) {
      console.error("Failed to decrypt data:", error);
      return null;
    }
  };

  const updUserData = (key, value) => {
    setAnalyticsUserData((prevData) => {
      const newData = {
        ...prevData,
        [key]: value,
      };

      const encryptedData = encryptData(newData);
      localStorage.setItem("memoryOS_AnalyticsUserData", encryptedData);

      return newData;
    });
  };

  const initializeUserData = () => {
    const encryptedData = localStorage.getItem("memoryOS_AnalyticsUserData");
    if (encryptedData) {
      const decryptedData = decryptData(encryptedData);
      if (decryptedData) {
        setAnalyticsUserData(decryptedData);
      }
    }
  };

  useEffect(() => {
    initializeUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contextValue = {
    analyticsUserData,
    updUserData,
  };

  return (
    <AnalyticsUserDataContext.Provider value={contextValue}>
      {children}
    </AnalyticsUserDataContext.Provider>
  );
};
