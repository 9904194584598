import React, { useEffect, useRef, useState } from "react";
import LogoHP from "../../../templates/LogoHP/LogoHP";

import styles from "./HeaderStyle.module.css";

const Header = () => {
  const containerRef = useRef(null);
  const [widthCont, setWidth] = useState("");
  const [styleLogo, setStyleLogo] = useState({});

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        setWidth(containerWidth);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    const newStyleLogo =
      widthCont >= 1440
        ? { width: "176px", height: "56px" }
        : { width: "128px", height: "41px" };

    setStyleLogo(newStyleLogo);
  }, [widthCont]);

  return (
    <div className={styles.header} ref={containerRef}>
      <LogoHP styleData={styleLogo} />
    </div>
  );
};

export default Header;
