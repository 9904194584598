import React from "react";
import { useDevMode } from "../../../context/DevMode";

import styles from "./DevModeStyle.module.css";

const DevMode = () => {
  const { generateNewVariant, devModeVariant } = useDevMode();

  const handleGenerateAndReload = () => {
    generateNewVariant();
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  return (
    <button onClick={handleGenerateAndReload} className={styles.button}>
      <span className={styles.buttonText}>Generate Variant</span>
      <div className={styles.currentDevVariant}>
        <span className={styles.currentDevVariantText}>{devModeVariant}</span>
      </div>
    </button>
  );
};

export default DevMode;
