import React, { useState } from "react";

import { usePageConfig } from "../../../context/PageConfigContext";
import { useRemoteConfig } from "../../../context/RemoteConfigContext";

import styles from "./MoreEfficientlyStyle.module.css";

const MoreEfficiently = () => {
  const { findDataComponent } = usePageConfig();

  const data = findDataComponent("MoreEfficiently");

  const { waitlistPageData } = useRemoteConfig();

  const [lessonsData] = useState(waitlistPageData.access_functionality);
  const [gameText] = useState(waitlistPageData.more_efficiently_game_text);

  return (
    <div className={styles.container}>
      <div className={styles.imgsContainer}>
        <img
          src="/img/iphone_full.png"
          alt="iphoneFull"
          className={styles.iphoneFull}
        />
        <img
          src="/img/iphone_shadow.png"
          alt="iphoneShadow"
          className={styles.iphoneShadow}
        />
        <div className={styles.imgTextCont}>
          <img
            src="/img/gamepad.png"
            alt="gamepad"
            className={styles.gamepad}
          />
          <span className={styles.imgText}>
            {lessonsData.number_micro_lessons} {gameText}
          </span>
        </div>
      </div>
      <div className={styles.textCont}>
        <span className={styles.headerText}>{data.headerText}</span>
        <span className={styles.mainText}>{data.mainText}</span>
      </div>
    </div>
  );
};

export default MoreEfficiently;
