import React from "react";
import { usePageConfig } from "../../../../context/PageConfigContext";

import VariantB from "./DesignRender/VariantB";

import styles from "./WithinMemoryOS.module.css";

const WithinMemoryOS = () => {
  const { findDataComponent } = usePageConfig();

  const data = findDataComponent("WithinMemoryOS");

  return (
    <div className={styles.container}>
      <div className={styles.headText}>{data.headerText}</div>

      <div className={styles.firstContainer}>
        {data.steps.map((step, index) => {
          return (
            <div key={index} className={styles.firstStepElement}>
              <div className={styles.WithinMemoryOSCheck} />
              <div className={styles.WithinMemoryOSText}>{step}</div>
            </div>
          );
        })}
      </div>
      <div className={styles.hrLine} />
      <VariantB />
    </div>
  );
};

export default WithinMemoryOS;
