import React, { useEffect, useState } from "react";

import styles from "./UserNameContainerStyle.module.css";

const UserNameContainer = ({
  placeholderInfo,
  updateValidUserDataAtIndex,
  updateUserData,
}) => {
  const [newUserName, setNewUserName] = useState("");
  const [pasted, setPasted] = useState(false);

  const handleInputChange = (e) => {
    const { value } = e.target;
    const truncatedValue = value.slice(0, 50);

    if (!pasted) {
      setNewUserName(truncatedValue);
    }

    setPasted(false);
  };

  const handlePaste = (e) => {
    const pastedValue = e.clipboardData.getData("text");
    const truncatedValue = pastedValue.slice(0, 50);
    setNewUserName(truncatedValue);
    setPasted(true);
  };

  useEffect(() => {
    if (newUserName.length !== 0 && newUserName.length >= 3) {
      updateValidUserDataAtIndex(1, true);
      updateUserData({ userName: newUserName });
    } else {
      updateValidUserDataAtIndex(1, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUserName]);
  return (
    <div className={styles.container}>
      {/* <span className={styles.textAboveInput}>{data.input.textAboveInput}</span> */}

      <input
        type="text"
        placeholder={placeholderInfo}
        name="name"
        autoComplete="given-name"
        required
        value={newUserName}
        onChange={handleInputChange}
        onPaste={handlePaste}
        className={styles.customInput}
      />
    </div>
  );
};

export default UserNameContainer;
