import React from "react";

import { usePromoCounter } from "../../../../../context/PromoIncrementCounerContext";

import styles from "./RightSectionStyle.module.css";

const RightSection = ({ data }) => {
  const { counter } = usePromoCounter();

  return (
    <div className={styles.container}>
      <img
        src="../img/awesomeImg.png"
        alt="Awesome"
        className={styles.awesomeImg}
      />
      <div className={styles.elipseContainer}>
        <img
          src="../img/elipseReserve.png"
          alt="elipse"
          className={styles.elipse}
        />
        <img
          src="../img/womenImg.png"
          alt="photoImg"
          className={styles.photo}
        />
      </div>
      <img src="../img/unionReserve.png" alt="Union" className={styles.union} />
      <div className={styles.unionContainer}>
        <div className={styles.unionTopBloc}>
          <img
            src="../img/peopleFrameReserve.png"
            alt="waitlist"
            className={styles.unionImg}
          />
          <span className={styles.unionCounter}>{counter}</span>
        </div>
        <span className={styles.unionText}>{data.waitlist_text}</span>
      </div>
      <div className={styles.topBlock}>
        <span className={styles.lifetimeAcc}>{data.lifetime_accounts}</span>
        <span className={styles.lifetimeText}>{data.lifetime_text}</span>
      </div>
    </div>
  );
};

export default RightSection;
