import React from "react";
import { usePageContent } from "../../../../context/PageContext";

import styles from "./DownSalePopupStyle.module.css";

const DownSalePopup = () => {
  const {
    pageContent,
    setShowHideDownSalePopup,
    setDownSaleStatus,
    productProcessing,
    setActiveProductIndex,
  } = usePageContent();

  const handleButtonClick = () => {
    setShowHideDownSalePopup(false);
    setDownSaleStatus(true);
    setActiveProductIndex(0);
    setTimeout(productProcessing(0), 100);
  };

  return (
    <div className={styles.shadowContainer} onClick={() => handleButtonClick()}>
      <div className={styles.ScrollContainer}>
        <div className={styles.container}>
          <div className={styles.topTextContainer}>
            <span className={styles.greenTextTop}>
              {pageContent.down_sale_popup.top_text[0]}{" "}
              <span className={styles.mainTextTop}>
                {pageContent.down_sale_popup.top_text[1]}
              </span>
            </span>
          </div>
          <div className={styles.mainTextContainer}>
            <span className={styles.grayTextMain}>
              {pageContent.down_sale_popup.main_text[0]}{" "}
              <span className={styles.whiteTextMain}>
                {pageContent.down_sale_popup.main_text[1]}
              </span>
            </span>
          </div>
          <img
            src={
              pageContent.down_sale_popup.img_link
                ? pageContent.down_sale_popup.img_link
                : "../img/planImgMO.png"
            }
            alt="Mo Downsale"
            className={styles.moImgDownsale}
          />
          <button
            onClick={() => setShowHideDownSalePopup(false)}
            className={styles.button}
          >
            <span className={styles.buttonText}>
              {pageContent.down_sale_popup.button_text}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DownSalePopup;
