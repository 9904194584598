import React from "react";
import { usePageContent } from "../../../../../../../context/PageContext";

import styles from "../PlanDetails.module.css";

const VariantC = () => {
  const { productOrder } = usePageContent();

  return (
    <div className={styles.vcContainer}>
      <span className={styles.totalTextVC}>Total:</span>
      <span className={styles.totalTextVC}>${productOrder.total_price}</span>
    </div>
  );
};

export default VariantC;
