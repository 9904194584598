import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { format, addMonths, addDays } from "date-fns";

import { useUrlChange } from "../hooks/urlChange";

import { useRemoteConfig } from "./RemoteConfigContext";
import { usePaymentForm } from "./PaymentFormContext";
import { useUrlContext } from "./urlContext";

import Loader from "../pages/templates/Loader/Loader";

const PageContext = createContext();

export const usePageContent = () => useContext(PageContext);

export const PageProvider = ({ children }) => {
  const { remotePageContent } = useRemoteConfig();

  const { setCurrentOrder } = usePaymentForm();

  const {
    applyDiscountPage,
    holidayPromoPath,
    holidayOldPromoPath,
    holidayOldPromoPathDuplicate,
  } = useUrlContext();

  const [isLoading, setIsLoading] = useState(true);

  const memoizedRemotePageContent = useMemo(() => {
    return remotePageContent;
  }, [remotePageContent]);

  const [timerTime, setTimerTime] = useState(0);
  const [showHideToggle, setShowHideToggle] = useState(false);
  const [showHideDiscountTrialBanner, setShowHideDiscountTrialBunner] =
    useState(false);
  const [activateDiscount, setActivateDiscount] = useState(null);
  const [discountBannerTrialProducts, setDiscountBannerTrialProducts] =
    useState([]);

  const [pageContent, setPageContent] = useState({});

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        if (Object.keys(memoizedRemotePageContent).length !== 0) {
          setShowHideToggle(memoizedRemotePageContent.toggle_button || false);
          setTimerTime(memoizedRemotePageContent.timer_time || 0);
          setPageContent(memoizedRemotePageContent.page_content || {});
          setShowHideDiscountTrialBunner(
            memoizedRemotePageContent.discount_design_trial_banner || false
          );
          setDiscountBannerTrialProducts(
            memoizedRemotePageContent.discount_banner_design_trial_products ||
              []
          );
          setActivateDiscount(memoizedRemotePageContent.discount_popup ?? true);
        }
      } catch (error) {
        console.error("Error loading remote content:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [memoizedRemotePageContent]);

  const [downSaleStatus, setDownSaleStatus] = useState(
    JSON.parse(localStorage.getItem("MemoryOSDownSale")) ?? false
  );

  useEffect(() => {
    localStorage.setItem("MemoryOSDownSale", downSaleStatus);
  }, [downSaleStatus]);

  const [toggleStatus, setToggleStatus] = useState(false);

  const [renderProducts, setRenderProducts] = useState([]);
  const [activeProductIndex, setActiveProductIndex] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [productOrder, setProductOrder] = useState({});
  const [showHideDownSalePopup, setShowHideDownSalePopup] = useState(false);
  const [showHideSpecialOfferPopup, setShowHideSpecialOfferPopup] =
    useState(false);

  const [currentPath] = useState(window.location.href);

  const isTargetPage =
    currentPath.includes("/new/plan") ||
    currentPath.includes("/apply-discount") ||
    currentPath.includes("/hp/reserve") ||
    currentPath.includes("/hp-23/reserve");

  useEffect(() => {
    if (downSaleStatus && isTargetPage) {
      productProcessing(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downSaleStatus, isTargetPage]);

  useEffect(() => {
    if (applyDiscountPage && isTargetPage) {
      productProcessing(0);
    }
    if (
      holidayPromoPath ||
      holidayOldPromoPath ||
      holidayOldPromoPathDuplicate
    ) {
      productProcessing(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    applyDiscountPage,
    holidayPromoPath,
    holidayOldPromoPath,
    holidayOldPromoPathDuplicate,
    isTargetPage,
    downSaleStatus,
  ]);

  const productProcessing = (index, status) => {
    if (downSaleStatus) {
      localStorage.setItem("MemoryOSDownSale", downSaleStatus);
    }
    setActiveProduct(index, status);
    if (index > 0) {
      setToggleStatus(false);
    }
  };

  const toogleProcessing = () => {
    if (toggleStatus) {
      setToggleStatus(false);
      productProcessing(0, false);
    } else {
      setToggleStatus(true);
      setActiveProductIndex(0);
      productProcessing(0, true);
    }
  };

  const setActiveProduct = (index, status) => {
    let updatedRenderProducts;

    if (!downSaleStatus) {
      updatedRenderProducts = [...memoizedRemotePageContent.default_products];
    } else {
      updatedRenderProducts = [...memoizedRemotePageContent.discount_products];
    }

    if (memoizedRemotePageContent.trial_products && status) {
      if (!downSaleStatus) {
        updatedRenderProducts = [
          ...memoizedRemotePageContent.trial_products[0],
        ];
      } else {
        updatedRenderProducts = [
          ...memoizedRemotePageContent.trial_products[1],
        ];
      }
    }

    setRenderProducts(updatedRenderProducts);

    const newSelectedProduct =
      updatedRenderProducts[index] || updatedRenderProducts[0];
    setSelectedProduct(newSelectedProduct);
    setOrder(newSelectedProduct);
  };

  const setOrder = (product) => {
    const createdProduct = {
      plan_name: product.plan_name,
      product_id: product.product_id,
      full_price: product.full_price,
      payment_form_text: product.payment_form_text || "",
      thankyou_page_title: product.thankyou_page_title,
      total_price: product.total_price,
      trial_payment_form_text: product.trial_payment_form_text,
      renewal_data: product.renewal_data,
      trial_status: product.trial_status || false,
      order_payment_form_text: product.order_payment_form_text || null,
    };

    setProductOrder(createdProduct);
    setCurrentOrder(createdProduct);
    localStorage.setItem(
      "MemoryOSCurrentOrder",
      JSON.stringify(createdProduct)
    );
  };

  const createTrialOrder = (index) => {
    const getAllTrialProducts = !downSaleStatus
      ? discountBannerTrialProducts[0]
      : discountBannerTrialProducts[1];

    const selectTrialProduct = getAllTrialProducts[index];

    if (selectTrialProduct) {
      const createdProduct = {
        plan_name: selectTrialProduct.plan_name,
        product_id: selectTrialProduct.product_id,
        full_price: selectTrialProduct.full_price,
        payment_form_text: selectTrialProduct.payment_form_text || "",
        thankyou_page_title: selectTrialProduct.thankyou_page_title,
        total_price: selectTrialProduct.total_price,
        trial_payment_form_text: selectTrialProduct.trial_payment_form_text,
        renewal_data: selectTrialProduct.renewal_data,
        order_payment_form_text:
          selectTrialProduct.order_payment_form_text || null,
        trial_status: true,
      };
      setProductOrder(createdProduct);
      setCurrentOrder(createdProduct);
      localStorage.setItem(
        "MemoryOSCurrentOrder",
        JSON.stringify(createdProduct)
      );
    }
  };

  const [renewalDate, setRenewalDate] = useState(false);

  const calculateRenewalDate = () => {
    try {
      const getProductRenewalData = JSON.parse(
        localStorage.getItem("MemoryOSCurrentOrder")
      );

      if (!getProductRenewalData || !getProductRenewalData.renewal_data) {
        setRenewalDate(false);
        return;
      }

      const renewalValue = getProductRenewalData.renewal_data;

      if (typeof renewalValue !== "number" || isNaN(renewalValue)) {
        setRenewalDate(false);
        return;
      }

      const currentDate = new Date();
      let nextRenewalDate;

      if (Number.isInteger(renewalValue)) {
        nextRenewalDate = addMonths(currentDate, renewalValue);
      } else {
        const days = Math.round(renewalValue * 10);
        nextRenewalDate = addDays(currentDate, days);
      }

      const formattedDate = format(nextRenewalDate, "dd MMM yyyy");
      setRenewalDate(formattedDate);
    } catch (error) {
      console.error("Error calculating renewal date:", error);
      setRenewalDate(false);
    }
  };

  const [userCancelDiscountHP, setUserCancelDiscountHP] = useState(false);

  useEffect(() => {
    if (userCancelDiscountHP) {
      localStorage.setItem("MemorryOSdiscountHP", userCancelDiscountHP);
    }
  }, [userCancelDiscountHP]);

  const currentUrl = useUrlChange();

  const correctRoutes = [
    "/hp/reserve",
    "/hp/reserve-essential",
    "/hp-23/reserve",
    "/hp-23/reserve-essential",
    "/hp-23d/reserve",
    "/hp-23d/reserve-essential",
  ];

  const routeCheck = (path) => {
    const url = new URL(path);
    const pathname = url.pathname;
    return correctRoutes.some((route) => pathname.includes(route));
  };

  useEffect(() => {
    if (routeCheck(currentUrl)) {
      const checkCanselDiscountStatus = JSON.parse(
        localStorage.getItem("MemorryOSdiscountHP")
      );
      if (checkCanselDiscountStatus) {
        localStorage.setItem("MemorryOSdiscountHP", "false");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUrl]);

  if (isLoading) {
    return <Loader />;
  }

  const contextValue = {
    timerTime,
    activateDiscount,
    showHideToggle,
    toggleStatus,
    setToggleStatus,
    showHideDiscountTrialBanner,
    activeProductIndex,
    setActiveProductIndex,
    pageContent,
    renderProducts,
    selectedProduct,
    productOrder,
    showHideDownSalePopup,
    setShowHideDownSalePopup,
    showHideSpecialOfferPopup,
    setShowHideSpecialOfferPopup,
    downSaleStatus,
    setDownSaleStatus,
    createTrialOrder,
    renewalDate,
    calculateRenewalDate,
    productProcessing,
    toogleProcessing,
    userCancelDiscountHP,
    setUserCancelDiscountHP,
  };

  return (
    <PageContext.Provider value={contextValue}>{children}</PageContext.Provider>
  );
};
