import React, { useState } from "react";
import { useRemoteConfig } from "../../../context/RemoteConfigContext";

import { useNavigate } from "react-router-dom";
import { preserveUrlParams } from "../../../utils/urlParams";
import { useNavigationPath } from "../../../hooks/navigationPath";


import styles from "./TopDiscount.module.css";
import { useButtonRef } from "../../../context/ButtonRefContext";
import LogoHP from "../../templates/LogoHP/LogoHP";

const TopDiscount = () => {
  const { waitlistPageData } = useRemoteConfig();
  const { paymentButtonRef } = useButtonRef();

  const [data] = useState(waitlistPageData.top_discount);

  const navigate = useNavigate();

  const reservePath = useNavigationPath("reserve");

  const handleButtonClick = () => {
    const userData = JSON.parse(localStorage.getItem("MemoryOSUserData"));

    if (userData) {
      navigate(preserveUrlParams(reservePath));
    } else if (!userData && paymentButtonRef.current) {
      paymentButtonRef.current.click();
    }
  };

  return (
    <div className={styles.containerDiscount}>
      <div className={styles.topLogo}>
        <LogoHP />
      </div>
      <div className={styles.topContent}>
        <button
          className={styles.containerDiscountButton}
          onClick={handleButtonClick}
        >
          <span className={styles.containerDiscountButtonText}>
            {data.button_text}
          </span>
        </button>
      </div>
    </div>
  );
};

export default TopDiscount;
