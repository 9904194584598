import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import VideoLoader from "../VideoLoader/VideoLoader";

const YouTubePlayer = ({
  videoId,
  width = "100%",
  height = "220px",
  onReady,
  className,
  borderRadius = "10px",
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const playerRef = useRef(null);
  const playerInstanceRef = useRef(null);

  useEffect(() => {
    if (!window.YT) {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
  }, []);

  useEffect(() => {
    const initPlayer = () => {
      if (playerInstanceRef.current) {
        playerInstanceRef.current.loadVideoById(videoId);
        return;
      }

      playerInstanceRef.current = new window.YT.Player(playerRef.current, {
        videoId,
        playerVars: {
          playsinline: 1,
          modestbranding: 1,
          enablejsapi: 1,
          origin: window.location.origin,
          rel: 0,
          controls: 1,
          autoplay: 0,
          widget_referrer: window.location.origin,
        },
        events: {
          onReady: (event) => {
            setIsLoading(false);
            if (onReady) onReady(event);
          },
          onStateChange: (event) => {
            // console.log("Player state changed:", event.data);
          },
          onError: (error) => {
            console.error("YouTube player error:", error);
            setIsLoading(false);
          },
        },
      });
    };

    if (window.YT && window.YT.Player) {
      initPlayer();
    } else {
      const previousCallback = window.onYouTubeIframeAPIReady;
      window.onYouTubeIframeAPIReady = () => {
        if (previousCallback) previousCallback();
        initPlayer();
      };
    }

    return () => {
      if (playerInstanceRef.current) {
        playerInstanceRef.current.destroy();
        playerInstanceRef.current = null;
      }
    };
  }, [videoId, onReady]);

  const playVideo = () => {
    if (playerInstanceRef.current) {
      playerInstanceRef.current.playVideo();
    }
  };

  return (
    <div
      className={className}
      style={{
        borderRadius,
        overflow: "hidden",
        width,
        height,
        position: "relative",
        cursor: "pointer",
      }}
      onClick={playVideo}
    >
      {isLoading && (
        <div
          style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
        >
          <VideoLoader />
        </div>
      )}
      <div
        ref={playerRef}
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
        }}
      />
    </div>
  );
};

YouTubePlayer.propTypes = {
  videoId: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  onReady: PropTypes.func,
  className: PropTypes.string,
  borderRadius: PropTypes.string,
};

export default YouTubePlayer;
