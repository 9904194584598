import React from "react";

import styles from "./PaySafeSecureStyle.module.css";

const PaySafeSecure = () => {
  return (
    <div className={styles.container}>
      <div className={styles.hrLine} />
      <div className={styles.mainContainer}>
        <img
          src="../img/FrameReservePayment.png"
          alt="FrameReservePayment"
          className={styles.imgStyle}
        />
        <span className={styles.text}>Pay safe & secure</span>
      </div>
    </div>
  );
};

export default PaySafeSecure;
