import React from "react";
import { usePageConfig } from "../../../../../context/PageConfigContext";

import VariantB from "./RenderVariants/VariantB";

import styles from "./ElementsSliderStyles.module.css";

const ElementsSlider = ({ renderData }) => {
  const { findDataComponent } = usePageConfig();

  const data = findDataComponent("WithinMemoryOS");

  return (
    <div className={styles.container}>
      <span className={styles.thirdStepsHeaderText}>
        {data.thirdStepsHeaderText}
      </span>
      <VariantB renderData={renderData} />
    </div>
  );
};

export default ElementsSlider;
