import React from "react";

import { usePageConfig } from "../../../../context/PageConfigContext";

import styles from "./MoneyBackGuarantee.module.css";

const MoneyBackGuarantee = () => {
  const { findDataComponent } = usePageConfig();

  const data = findDataComponent("MoneyBackGuarantee");

  const splitBottomText = data.mainText.bottom;
  const splitTextParts = splitBottomText.split(data.mainText.bottomLinkText);

  const bottomTextStyles = (
    <span>
      {splitTextParts[0]}
      {
        <a
          href={data.mainText.link}
          className={styles.subscrText}
          target="blank"
        >
          {data.mainText.bottomLinkText}
        </a>
      }
      {splitTextParts[1]}
    </span>
  );

  return (
    <div className={styles.container}>
      <img
        src="/img/money-back-garantee.png"
        alt=""
        className={styles.img}
      />

      <div className={styles.headerText}>{data.headerText}</div>
      <div className={styles.FooterContainerText}>
        <span className={styles.FooterText}>{data.mainText.top}</span>
        <span className={styles.FooterSpecialText}>{data.mainText.main}</span>
        <span className={styles.FooterText}>{bottomTextStyles}</span>
      </div>
    </div>
  );
};

export default MoneyBackGuarantee;
