import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { useEnvContext } from "./EnvironmentContext";
import Loader from "../pages/templates/Loader/Loader";

const DevMode = createContext();

export const useDevMode = () => useContext(DevMode);

const LOCAL_STORAGE_KEY = "MemoryOSDevModeVariant";

export const DevModeProvider = ({ children }) => {
  const { isProduction } = useEnvContext();

  const [isDevModeReady, setIsDevModeReady] = useState(false);
  const [devModeVariant, setDevModeVariant] = useState();

  const generateRandomNumber = (max, min) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const initializeDevMode = useCallback(() => {
    if (!isProduction) {
      const storedVariant = localStorage.getItem(LOCAL_STORAGE_KEY);
      if (storedVariant) {
        setDevModeVariant(Number(storedVariant));
      } else {
        const newVariant = generateRandomNumber(4, 1);
        setDevModeVariant(newVariant);
        localStorage.setItem(LOCAL_STORAGE_KEY, newVariant.toString());
      }
    } else {
      setDevModeVariant(null);
      localStorage.removeItem(LOCAL_STORAGE_KEY);
    }
    setIsDevModeReady(true);
  }, [isProduction]);

  useEffect(() => {
    initializeDevMode();
  }, [initializeDevMode]);

  useEffect(() => {
    if (devModeVariant !== undefined) {
      console.log(`Current devModeVariant: ${devModeVariant}`);
    } else if (devModeVariant === null) {
      console.log(`DevMode is disabled in production.`);
    }
  }, [devModeVariant]);

  const generateNewVariant = useCallback(() => {
    if (!isProduction) {
      const newVariant = generateRandomNumber(4, 1);
      setDevModeVariant(newVariant);
      localStorage.setItem(LOCAL_STORAGE_KEY, newVariant.toString());
      console.log(`New devModeVariant generated: ${newVariant}`);
    } else {
      console.log(`Cannot generate new variant in production mode.`);
    }
  }, [isProduction]);

  const contextValue = {
    devModeVariant,
    generateNewVariant,
  };

  if (!isDevModeReady) {
    return <Loader />;
  }

  return <DevMode.Provider value={contextValue}>{children}</DevMode.Provider>;
};
