import React, { useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";

import SliderRating from "./SliderRating/SliderRating";

import styles from "./slider.module.css";

SwiperCore.use([Autoplay, Pagination, Navigation]);

const StoreSlider = ({ dataReview, delayProps }) => {
  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.autoplay.start();
    }
  }, []);

  const handlePrevSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNextSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <Swiper
      ref={swiperRef}
      slidesPerView={"auto"}
      spaceBetween={20}
      pagination={false}
      autoplay={{
        delay: delayProps,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
      loop={true}
      centeredSlides={true}
      centeredSlidesBounds={true}
      className={styles.SwiperContainer}
    >
      <div className={styles.shadowLeftCont} />
      <div className={styles.shadowRightCont} />
      {dataReview.map((element, index) => {
        return (
          <SwiperSlide className={styles.feedbackContainer} key={index}>
            <div className={styles.topContent}>
              <img
                src={`/img/${
                  element.source === "AppStore"
                    ? "icon_app_store.png"
                    : element.source === "InApp"
                    ? "icon_in_app.png"
                    : "icon_google_play.png"
                }`}
                alt={element.title}
                className={styles.sourceImg}
              />

              <span className={styles.feedback}>{element.feedback}</span>
            </div>
            <div className={styles.botContent}>
              <SliderRating rate={element.rating} />
              <span className={styles.userName}>{element.userName}</span>
            </div>
          </SwiperSlide>
        );
      })}
      <div className={styles.buttonContainer}>
        <button className={styles.buttonArrowL} onClick={handlePrevSlide}>
          <img
            src="/img/ArrowLeft_Btn.png"
            alt="ArrowLeft"
            className={styles.arrowImg}
          />
        </button>
        <button className={styles.buttonArrowR} onClick={handleNextSlide}>
          <img
            src="/img/ArrowRight_Btn.png"
            alt="ArrowRight"
            className={styles.arrowImg}
          />
        </button>
      </div>
    </Swiper>
  );
};

export default StoreSlider;
