import React, { useState, useEffect } from "react";
import { usePageConfig } from "../../../../context/PageConfigContext";
import { usePageContent } from "../../../../context/PageContext";

import SpecialOfferPaymentButton from "../PaymentButton/SpecialOfferPaymentButton";
import Loader from "../../../templates/Loader/Loader";

import styles from "./SpecialOfferPopupStyle.module.css";

const SpecialOfferPopup = () => {
  const { findDataComponent } = usePageConfig();
  const { setShowHideSpecialOfferPopup, createTrialOrder, productProcessing } =
    usePageContent();
  const data = findDataComponent("SpecialOfferPopup");

  const [activeIndex, setActiveIndex] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const defaultIndex = data.trialPriceOptions.findIndex(
      (element) => element.mostCommon
    );
    if (defaultIndex !== -1) {
      handleButtonClick(defaultIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeIndex >= 0) {
      createTrialOrder(activeIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  const handleButtonClick = (elementIndex) => {
    setActiveIndex(elementIndex);
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const handleCloseButtonClick = () => {
    setShowHideSpecialOfferPopup(false);
    productProcessing(0);
  };

  return (
    <div className={styles.shadowContainer}>
      <div className={styles.ScrollContainer}>
        <div className={styles.container}>
          <button
            className={styles.closeButton}
            onClick={() => handleCloseButtonClick()}
          >
            <img
              src="../img/SpecialOfferCloseImg.png"
              alt="SpecialOfferCloseImg"
              className={styles.closeButtonImg}
            />
          </button>
          <div className={styles.topTextContainer}>
            <span className={styles.topText}>{data.topText}</span>
            <span className={styles.mainText}>{data.mainText}</span>
          </div>
          {!imageLoaded && <Loader />}
          <img
            src="../img/elephantWithHeart.png"
            alt="elephantWithHeart"
            className={styles.elephantWithHeart}
            onLoad={handleImageLoad}
            style={{ display: imageLoaded ? "block" : "none" }}
          />
          <div className={styles.specialPriceContainer}>
            {data.trialPriceOptions.map((element, index) => (
              <button
                key={index}
                className={`${styles.priceElement} ${
                  index === activeIndex ? styles.priceElementActive : ""
                }`}
                onClick={() => handleButtonClick(index)}
              >
                <span className={styles.textElement}>{element.price}</span>
              </button>
            ))}
            <div className={styles.mostCommon}>
              <span className={styles.mostCommonText}>
                {data.mostCommonText}
              </span>
            </div>
          </div>
          <SpecialOfferPaymentButton />
        </div>
      </div>
    </div>
  );
};

export default SpecialOfferPopup;
