import React, { useEffect, useState } from "react";
import { usePageContent } from "../../../../../context/PageContext";
import { usePageConfig } from "../../../../../context/PageConfigContext";
import Loading from "../../Loading/Loading";
import styles from "../PleaseNoteStyle.module.css";

const VariantB = () => {
  const { findDataComponent } = usePageConfig();
  const { selectedProduct, toggleStatus } = usePageContent();
  const data = findDataComponent("PleaseNote");

  const [isLoading, setIsLoading] = useState(true);
  const [renderText, setRenderText] = useState([]); 

  useEffect(() => {
    if (selectedProduct) {
      setIsLoading(true);
      const text = !toggleStatus
        ? selectedProduct?.please_note_text
        : selectedProduct?.trial_please_note_text;

      setRenderText(text || []);
      setIsLoading(false);
    }
  }, [toggleStatus, selectedProduct]);

  return (
    <span className={styles.mainText}>
      <span className={styles.hightText}>{data.textHight}</span>
      {isLoading ? (
        <Loading />
      ) : (
        <span>
          {renderText.map((element, index) => (
            <span key={index}>
              {element.text_link ? (
                <a
                  href={element.url}
                  className={styles.subscrText}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  {element.part_text}{" "}
                </a>
              ) : (
                element.part_text
              )}
            </span>
          ))}
        </span>
      )}
    </span>
  );
};

export default VariantB;
