import React, { createContext, useContext, useEffect, useState } from "react";

const PreloadedImagesContext = createContext({});

const useImagePreloader = (steps) => {
  const [imagesMap, setImagesMap] = useState(new Map());
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!steps?.length) return;

    const imageUrls = new Set();

    steps.forEach((step) => {
      if (step.image) {
        imageUrls.add(step.image);
      }
      if (step.answers) {
        step.answers.forEach((answer) => {
          if (answer.img) {
            imageUrls.add(answer.img);
          }
        });
      }
    });

    const urlsArray = Array.from(imageUrls);
    if (urlsArray.length === 0) {
      setIsLoading(false);
      return;
    }

    let loadedCount = 0;
    const newImagesMap = new Map();

    urlsArray.forEach((url) => {
      const img = new Image();

      img.onload = () => {
        newImagesMap.set(url, img);
        loadedCount++;

        if (loadedCount === urlsArray.length) {
          setImagesMap(newImagesMap);
          setIsLoading(false);
        }
      };

      img.onerror = () => {
        console.warn(`Failed to load image: ${url}`);
        loadedCount++;

        if (loadedCount === urlsArray.length) {
          setImagesMap(newImagesMap);
          setIsLoading(false);
        }
      };

      img.src = url;
    });

    return () => {
      urlsArray.forEach((url) => {
        const img = newImagesMap.get(url);
        if (img) {
          img.onload = null;
          img.onerror = null;
        }
      });
    };
  }, [steps]);

  return { imagesMap, isLoading };
};

export const PreloadedImagesProvider = ({ children, steps }) => {
  const preloadedImages = useImagePreloader(steps);

  return (
    <PreloadedImagesContext.Provider value={preloadedImages}>
      {children}
    </PreloadedImagesContext.Provider>
  );
};

export const usePreloadedImages = () => {
  const context = useContext(PreloadedImagesContext);
  if (!context) {
    throw new Error("PreloadedImagesProvider not found, provider error");
  }
  return context;
};

export const getPreloadedImageUrl = (imagesMap, originalUrl) => {
  const preloadedImage = imagesMap.get(originalUrl);
  return preloadedImage ? preloadedImage.src : originalUrl;
};
