import React, { createContext, useContext, useState, useEffect } from "react";
import Loader from "../pages/templates/Loader/Loader";
import LZUTF8 from "lzutf8";

const UrlContext = createContext();

export const useUrlContext = () => {
  return useContext(UrlContext);
};

export const UrlProvider = ({ children }) => {
  const [queryParams, setQueryParams] = useState({});
  const [isInitialized, setIsInitialized] = useState(false);

  const getCurrentPath = async () => {
    return window.location.href;
  };

  useEffect(() => {
    const initializeContext = async () => {
      const storedParams =
        JSON.parse(localStorage.getItem("MemoryOSQueryParams")) || {};
      const params = getQueryParameters();
      let updatedParams = { ...params };

      if (Object.keys(params).length > 0) {
        setQueryParams(updatedParams);
        localStorage.setItem(
          "MemoryOSQueryParams",
          JSON.stringify(updatedParams)
        );
      } else if (Object.keys(storedParams).length > 0) {
        setQueryParams(storedParams);
      }

      setIsInitialized(true);
    };

    initializeContext();
  }, []);

  const getQueryParameters = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const dataUrl = {};

    for (const [key, value] of urlParams.entries()) {
      dataUrl[key] = value;
    }

    return dataUrl;
  };

  const [defaultPagePath, setDefaultPagePath] = useState(null);
  const [applyDiscountPage, setApplyDiscountPage] = useState(null);
  const [holidayPromoPath, setHolidayPromoPath] = useState(null);
  const [holidayOldPromoPath, setHolidayOldPromoPath] = useState(null);
  const [holidayOldPromoPathDuplicate, setHolidayOldPromoPathDuplicate] =
    useState(null);

  const path = window.location.href;

  useEffect(() => {
    if (path) {
      const defaultPath = /\/new(\/|$|\?|#)/.test(path);

      const hasDiscountPath = /\/apply-discount(\/|$|\?|#)/.test(path);

      const hasHolidayPromoPath = /\/hp(\/|$|\?|#)/.test(path);

      const hasHolidayPromoOldPath = /\/hp-23(\/|$|\?|#)/.test(path);

      const hasHolidayPromoOldPathDuplicate = /\/hp-23d(\/|$|\?|#)/.test(path);

      if (hasDiscountPath) {
        localStorage.setItem("MemoryOSDownSale", false);
      }

      setDefaultPagePath(defaultPath);
      setApplyDiscountPage(hasDiscountPath);
      setHolidayPromoPath(hasHolidayPromoPath);
      setHolidayOldPromoPath(hasHolidayPromoOldPath);
      setHolidayOldPromoPathDuplicate(hasHolidayPromoOldPathDuplicate);
    }
  }, [path]);

  const [decompressedData, setDecompressedData] = useState(null);

  useEffect(() => {
    try {
      const raw = window.location.search.split("payload=")[1];
      if (!raw) {
        console.warn("Payload not found in URL");
        return;
      }

      const payload = raw.includes("&") ? raw.split("&")[0] : raw;

      const decompressed = LZUTF8.decompress(payload, {
        inputEncoding: "Base64",
        outputEncoding: "String",
      });

      console.log("Decompressed:", decompressed);

      if (!decompressed.includes("~")) {
        console.warn("Invalid decompressed data format - missing separator");
        return;
      }

      const [userName = "", userEmail = ""] = decompressed.split("~");

      const parsedData = {
        userName: userName?.trim() || "",
        userEmail: userEmail?.trim() || "",
      };

      setDecompressedData(parsedData);
    } catch (error) {
      console.error("Decompression error:", error.message);
    }
  }, []);

  useEffect(() => {
    if (decompressedData) {
      const checkLocaluserParams = JSON.parse(
        localStorage.getItem("MemoryOSUserData")
      );
      if (!checkLocaluserParams) {
        localStorage.setItem(
          "MemoryOSUserData",
          JSON.stringify(decompressedData)
        );
        console.warn("setted user data from memoryos.com");
      }
    }
  }, [decompressedData]);

  const contextValue = {
    queryParams,
    getCurrentPath,
    defaultPagePath,
    applyDiscountPage,
    holidayPromoPath,
    holidayOldPromoPath,
    holidayOldPromoPathDuplicate,
  };

  if (!isInitialized) {
    return <Loader />;
  }

  return (
    <UrlContext.Provider value={contextValue}>{children}</UrlContext.Provider>
  );
};
