import React, { useState } from "react";

import styles from "./GarantyInfoStyle.module.css";
import { usePageContent } from "../../../../context/PageContext";

const GarantyInfo = () => {
  const { pageContent, downSaleStatus } = usePageContent();
  const [data] = useState(pageContent.garanty_info);

  return (
    <div className={styles.container}>
      <div className={styles.element}>
        <img
          src="../img/Refund.png"
          alt="Refund"
          style={{ width: "106px", height: "84px" }}
        />
        <span className={styles.headerText}>{data.refund.header_text}</span>
        <span className={styles.mainText}>
          {!downSaleStatus
            ? data.refund.main_text[0]
            : data.refund.main_text[1]}
        </span>
      </div>
      <div className={styles.element}>
        <img
          src="../img/Transparency.png"
          alt="Transparency"
          style={{ width: "92px", height: "84px" }}
        />
        <span className={styles.headerText}>
          {data.transparency.header_text}
        </span>
        <span className={styles.mainText}>{data.transparency.main_text}</span>
      </div>
      <div className={styles.element}>
        <img
          src="../img/Secure.png"
          alt="Secure"
          style={{ width: "92px", height: "84px" }}
        />
        <span className={styles.headerText}>{data.secure.header_text}</span>
        <span className={styles.mainText}>{data.secure.main_text}</span>
      </div>
    </div>
  );
};

export default GarantyInfo;
