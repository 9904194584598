import React from "react";
import { useRemoteConfig } from "../../../../context/RemoteConfigContext";
import { useButtonRef } from "../../../../context/ButtonRefContext";

import LogoHP from "../../../templates/LogoHP/LogoHP";

import styles from "./NavBarStyle.module.css";

const NavBar = () => {
  const { reservePageData } = useRemoteConfig();

  const { paymentButtonRef } = useButtonRef();

  const handleButtonClick = () => {
    if (paymentButtonRef.current) {
      paymentButtonRef.current.click();
    }
  };

  return (
    <div className={styles.containerDiscount}>
      <div className={styles.topLogo}>
        <LogoHP />
      </div>
      <div className={styles.topContent}>
        <button
          className={styles.containerDiscountButton}
          onClick={handleButtonClick}
        >
          <span className={styles.containerDiscountButtonText}>
            {reservePageData.nav_bar_btn_text}
          </span>
        </button>
      </div>
    </div>
  );
};

export default NavBar;
