import React from "react";
import { usePageContent } from "../../../../../../../context/PageContext";
import { useUrlContext } from "../../../../../../../context/urlContext";

import PaymentSystems from "../../assets/PaymentSystems";
import GraySafeIconSVG from "../../assets/GraySafeIcon";

import styles from "../../FooterBlockStyle.module.css";

const VariantB = () => {
  const { pageContent, toggleStatus, activeProductIndex } = usePageContent();

  const { applyDiscountPage } = useUrlContext();

  const renderForPlanPage = () => {
    return (
      <>
        {pageContent.first_subscription_options
          .changing_secure_text_toogle_active ? (
          <>
            {toggleStatus ? (
              <div className={styles.hrLineTwo} />
            ) : (
              <div className={styles.hrLine} />
            )}
            <div className={styles.iconTextContainer}>
              <GraySafeIconSVG />
              <span className={styles.headerText}>
                {toggleStatus
                  ? pageContent.first_subscription_options.text_secure_block[1]
                  : pageContent.first_subscription_options.text_secure_block[0]}
              </span>
            </div>
            {toggleStatus ? (
              <div className={styles.hrLineTwo} />
            ) : (
              <div className={styles.hrLine} />
            )}
          </>
        ) : (
          <>
            <div className={styles.hrLine} />
            <GraySafeIconSVG />
            <span className={styles.headerText}>
              {pageContent.first_subscription_options.text_secure_block[0]}
            </span>
            <div className={styles.hrLine} />
          </>
        )}
      </>
    );
  };

  const renderForApplyDiscountPage = () => {
    return (
      <>
        {!activeProductIndex ? (
          <div className={styles.hrLineTwo} />
        ) : (
          <div className={styles.hrLine} />
        )}
        <div className={styles.iconTextContainer}>
          <GraySafeIconSVG />
          <span className={styles.headerText}>
            {!activeProductIndex
              ? pageContent.first_subscription_options.text_secure_block[1]
              : pageContent.first_subscription_options.text_secure_block[0]}
          </span>
        </div>
        {!activeProductIndex ? (
          <div className={styles.hrLineTwo} />
        ) : (
          <div className={styles.hrLine} />
        )}
      </>
    );
  };

  return (
    <div>
      <div className={styles.headerContainer}>
        {!applyDiscountPage && renderForPlanPage()}
        {applyDiscountPage && renderForApplyDiscountPage()}
      </div>
      <PaymentSystems />
    </div>
  );
};

export default VariantB;
