import React from "react";
import { usePageContent } from "../../../../../context/PageContext";

import MainButton from "../../MainButton/MainButton";

import styles from "../BottomComponentStyle.module.css";

const LeftSection = ({ data }) => {
  const { downSaleStatus } = usePageContent();
  

  return (
    <div className={styles.leftContainer}>
      <span className={styles.title}>{data.title}</span>
      <div className={styles.buttonContainer}>
        <MainButton
          width={"242px"}
          height={"52px"}
          btnText={!downSaleStatus ? data.button_text[0] : data.button_text[1]}
          undBtnText={data.under_button_text}
        />
      </div>
    </div>
  );
};

export default LeftSection;
