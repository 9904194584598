import React, { createContext, useContext, useState } from "react";
// import { useBlackFriday } from "./BFContext";
import { useRemoteConfig } from "./RemoteConfigContext";

const PaymentContext = createContext({});

export const usePaymentBlock = () => useContext(PaymentContext);

export const PaymentProvider = ({ children }) => {
  const { appData } = useRemoteConfig();
  // const { selectedOptionBF, dataUser, option, setOption } = useBlackFriday();

  const [startNameEmailForm, setStartNameEmailForm] = useState(false);
  const [startPaymentForm, setStartPaymentForm] = useState(false);
  const [startActiveSubscForm, setStartActiveSubscForm] = useState(false);

  const [loader, setLoader] = useState(false);

  const rewriteEmail = (email) => {
    const savedData = JSON.parse(localStorage.getItem("MemoryOSUserData"));
    if (savedData !== null) {
      savedData.userEmail = email;

      localStorage.setItem("MemoryOSUserData", JSON.stringify(savedData));

      const timerId = setTimeout(() => {
        setLoader(false);
        loadingForm("StartPaymentForm");
      }, 1000);
      return () => clearTimeout(timerId);
    }
  };

  const loadingForm = async (key) => {
    switch (key) {
      case "StartPaymentForm":
        setStartPaymentForm(true);
        break;
      case "StartNameEmailForm":
        setStartNameEmailForm(true);
        break;
      case "StartActiveSubscForm":
        setStartActiveSubscForm(true);
        break;
      case "UpgradePlan":
        setStartPaymentForm(true);
        break;
      default:
        const userData = JSON.parse(localStorage.getItem("MemoryOSUserData"));
        return userData !== null
          ? loadingForm("StartPaymentForm")
          : loadingForm("StartNameEmailForm");
    }
  };

  const setOrderLocalData = (option) => {
    const currentOrderData = {
      origin: appData.REACT_APP_ORIGIN,
      fullPlanPrice: option.fullPlanPrice,
      trialDays: option.trialDays,
      total: option.priceTotal,
      planExpiration: option.days
        ? `days`
        : option.month > 1
        ? `months`
        : `month`,
      planExpirationDate: option.days ? option.days : option.month,
      optionPlanName: option.optionPlanName ? option.optionPlanName : null,
      varOption: option.varOpt,
      planName: option.fullPlanName,
      currency: "USD",
    };

    localStorage.setItem(
      "MemoryOSOrderLocalData",
      JSON.stringify(currentOrderData)
    );
  };

  const contextValue = {
    loader,
    setLoader,
    startNameEmailForm,
    setStartNameEmailForm,
    startPaymentForm,
    setStartPaymentForm,
    startActiveSubscForm,
    setStartActiveSubscForm,
    rewriteEmail,
    loadingForm,
    setOrderLocalData,
  };

  return (
    <PaymentContext.Provider value={contextValue}>
      {children}
    </PaymentContext.Provider>
  );
};
