import React, { useState } from "react";

import { useRemoteConfig } from "../../../context/RemoteConfigContext";
import { useButtonRef } from "../../../context/ButtonRefContext";

import { useNavigate } from "react-router-dom";
import { useNavigationPath } from "../../../hooks/navigationPath";
import { preserveUrlParams } from "../../../utils/urlParams";

import ImgBlock from "./ImgBlock/ImgBlock";
import RatingContainer from "./RatingContainer/RatingContainer";

import styles from "./TopComponentStyle.module.css";

const TopComponent = () => {
  const { waitlistPageData } = useRemoteConfig();
  const { paymentButtonRef } = useButtonRef();

  const [data] = useState(waitlistPageData.top_component);

  const navigate = useNavigate();

  const reservePath = useNavigationPath("reserve");

  const handleButtonClick = () => {
    const userData = JSON.parse(localStorage.getItem("MemoryOSUserData"));

    if (userData) {
      navigate(preserveUrlParams(reservePath));
    } else if (!userData && paymentButtonRef.current) {
      paymentButtonRef.current.click();
    }
  };

  return (
    <div
      className={styles.container}
      style={{
        marginTop: "40px",
      }}
    >
      <div className={styles.ImgModuleContainer}>
        <div className={styles.titleBlock}>
          <span className={styles.title}>{data.title}</span>
          <span className={styles.subTitle}>{data.sub_title}</span>
        </div>
        <button className={styles.button} onClick={handleButtonClick}>
          <span className={styles.buttonText}>{data.button_text}</span>
        </button>
        <ImgBlock />
        <span className={styles.bottomText}>{data.bottom_text}</span>
      </div>
      <div className={styles.ratingContainer}>
        <RatingContainer />
        <span className={styles.ratingText}>{data.rating_text}</span>
      </div>
    </div>
  );
};

export default TopComponent;
