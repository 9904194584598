import React, { useRef } from "react";

import { useResizeContainer } from "../../../../hooks/resizeContainer";

import { usePageConfig } from "../../../../context/PageConfigContext";

import YouTubePlayer from "../../../templates/YoutubePlayer/YoutubePlayer";
import NewsContainer from "./NewsContainer/NewsContainer";
// import FeatureSlider from "./FeatureSlider/FeatureSlider";

import styles from "./YourGrandmaster.module.css";

const YourGrandmaster = () => {
  const { findDataComponent } = usePageConfig();

  const data = findDataComponent("YourGrandmaster");

  const containerGrandmasterRef = useRef(null);

  const { widthCont, heightCont, radiusCont } = useResizeContainer(
    containerGrandmasterRef
  );

  return (
    <div className={styles.YourGrandmasterContainer}>
      <span className={styles.headerText}>{data.headerText}</span>
      <span className={styles.mainText}>{data.mainText}</span>
      <span className={styles.mainText}>{data.footerText}</span>
      <div className={styles.awardsContainer}>
        {data.awards.map((element, index) => {
          return (
            <div key={index} className={styles.awardElement}>
              <img
                src="/img/Layer_cup.png"
                alt="Jonas achievement"
                className={styles.layerCup}
              />
              <span className={styles.awardText}>{element}</span>
            </div>
          );
        })}
      </div>
      <div className={styles.sliderPosition}>
        <NewsContainer featuredText={data.featuredText} />
        {/* <FeatureSlider /> */}
      </div>
      <div className={styles.videoContainer} ref={containerGrandmasterRef}>
        <YouTubePlayer
          videoId={data.youtube_video_id}
          width={widthCont}
          height={heightCont}
          borderRadius={radiusCont}
        />
      </div>
      <div className={styles.backgroundImg} />
    </div>
  );
};

export default YourGrandmaster;
