import { useEffect, useState } from "react";

export const useResizeContainer = (containerRef) => {
  const [widthCont, setWidth] = useState("");
  const [heightCont, setHeight] = useState("");
  const [radiusCont, setRadius] = useState("");

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const containerHeight = containerRef.current.offsetHeight;
        setWidth(`${containerWidth}px`);
        setHeight(`${containerHeight}px`);
        const containerStyles = window.getComputedStyle(containerRef.current);
        const borderRadius = containerStyles.getPropertyValue("border-radius");
        setRadius(borderRadius);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    handleResize();

    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef]);

  return { widthCont, heightCont, radiusCont };
};
