import React from "react";

import {
  Step,
  Column,
  Title,
  Image,
  Copy,
  Banner,
  BannerHead,
  BannerName,
  BannerRating,
  Star,
  BannerCopy,
  Button,
} from "../../styles";
import VideoPlayer from "../../../templates/VideoPlayer/VideoPlayer";
import {
  getPreloadedImageUrl,
  usePreloadedImages,
} from "../../../../context/PreloadedImagesContext";
import Loading from "../Loading/Loading";

const RatingStep = ({ id, step, handleNextStep }) => {
  const { imagesMap, isLoading } = usePreloadedImages();
  const imageUrl = step.image
    ? getPreloadedImageUrl(imagesMap, step.image)
    : null;
  return (
    <Step>
      <Column>
        <Image>
          {isLoading && <Loading />}
          {/* <img src={step.image} alt="" /> */}
          <img src={imageUrl} alt="" />
        </Image>
        {step.video ? (
          <div>
            <VideoPlayer
              videoId={step.video}
              thumbnail={step.thumbnail || ""}
            />
          </div>
        ) : null}
      </Column>
      <Column>
        <Title>{step.title}</Title>
        {step.copy.map((copy, index) => (
          <Copy key={`${id}-${index}`}>{copy}</Copy>
        ))}
        <Banner>
          <BannerHead>
            <BannerName>{step.banner.name}</BannerName>
            <BannerRating>
              {[...Array(5)].map((item, i) => (
                <Star
                  key={`star${id}-${i}`}
                  $isActive={!!(i < step.banner.stars)}
                />
              ))}
            </BannerRating>
          </BannerHead>
          <BannerCopy>{step.banner.copy}</BannerCopy>
        </Banner>
        <Button
          $isActive={true}
          onClick={() => handleNextStep({ id, type: "rating" })}
        >
          Continue
        </Button>
      </Column>
    </Step>
  );
};

export default RatingStep;
