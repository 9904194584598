import React from "react";
import { useTimer } from "../../../../context/TimerContext";
import { useButtonRef } from "../../../../context/ButtonRefContext";
import { usePageConfig } from "../../../../context/PageConfigContext";
import { usePageContent } from "../../../../context/PageContext";
import { useEvent } from "../../../../context/EventContext";

import styles from "./PaymentButtonStyle.module.css";

const SpecialOfferPaymentButton = () => {
  const { findDataComponent } = usePageConfig();
  const { analyticsEvent } = useEvent();

  const { setShowHideSpecialOfferPopup } = usePageContent();

  const { timer } = useTimer();

  const data = findDataComponent("NewPaymentButton");

  const { paymentButtonRef } = useButtonRef();

  const handleButtonClick = () => {
    if (paymentButtonRef.current) {
      setShowHideSpecialOfferPopup(false);
      paymentButtonRef.current.click();
      analyticsEvent("initiateCheckout");
    }
  };

  return (
    <button className={styles.specialBtnContainer} onClick={handleButtonClick}>
      <p className={styles.specialpaymentButtonText}>
        {timer > 0 ? data.buttonText[1] : data.buttonText[0]}{" "}
        <span className={styles.specialpaymentButtonTextHands}>🙌</span>
      </p>
    </button>
  );
};

export default SpecialOfferPaymentButton;
