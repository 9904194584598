import React from "react";
import { usePageContent } from "../../../../../context/PageContext";

import GraySafeBlock from "./GraySafeBlock/GraySafeBlock";
import FooterTextContainerTwo from "./FooterTextContainer/FooterTextContainerTwo";

import styles from "./FooterBlockStyle.module.css";

const FooterBlock = () => {
  const { selectedProduct, toggleStatus } = usePageContent();

  return (
    <div className={styles.container}>
      <GraySafeBlock />
      <FooterTextContainerTwo
        renderData={
          !toggleStatus
            ? selectedProduct?.second_subscription_options_bottom_text
            : selectedProduct?.trial_second_subscription_options_bottom_text
        }
      />
    </div>
  );
};

export default FooterBlock;
