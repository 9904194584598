import React, { useEffect, useRef, useState } from "react";

import { PromoCounterProvider } from "../../../context/PromoIncrementCounerContext";
import { usePageContent } from "../../../context/PageContext";
import { useScroll } from "../../../context/ScrollContext";
import { useEvent } from "../../../context/EventContext";

import NavBar from "./NavBar/NavBar";
import TopComponent from "./TopComponent/TopComponent";
import TimerComponent from "./TimerComponent/TimerComponent";
import JonasComponent from "./JonasComponent/JonasComponent";
import MainComponent from "./MainComponent/MainComponent";
import MissionComponent from "./MissionComponent/MissionComponent";
import BottomComponent from "./BottomComponent/BottomComponent";
import Footer from "./Footer/Footer";
import PaymentBlock from "./PaymentBlock/PaymentBlock";

import Loader from "../../templates/Loader/Loader";

import styles from "./BlackFridayDiscount.module.css";

const BlackFridayDiscount = () => {
  const { downSaleStatus, setDownSaleStatus } = usePageContent();
  const { analyticsEvent } = useEvent();

  useEffect(() => {
    if (!downSaleStatus) {
      setDownSaleStatus(true);
    }
    analyticsEvent("hpEssentialPageView");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downSaleStatus]);

  const [isLoading, setIsLoading] = useState(true);
  const { scrollToTopComponent } = useScroll();
  const PageContainerRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      scrollToTopComponent(PageContainerRef);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }, 100);
  }, [scrollToTopComponent]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <PromoCounterProvider>
      <div className={styles.container} ref={PageContainerRef}>
        <NavBar />
        <TopComponent />
        <TimerComponent />
        <JonasComponent />
        <MainComponent />
        <MissionComponent />
        <BottomComponent />
        <Footer />
      </div>
      <PaymentBlock />
    </PromoCounterProvider>
  );
};

export default BlackFridayDiscount;
