import React from "react";
import { usePageContent } from "../../../../../../context/PageContext";

import styles from "./MainBlockStyle.module.css";

const MainBlock = ({ data }) => {
  const { downSaleStatus } = usePageContent();

  const discountStyle = {
    border: "1px solid #ff5900",
    borderRadius: "16px",
    padding: "20px",
    boxSizing: "border-box",
  };

  return (
    <div className={styles.container}>
      <span className={styles.titlePartOne}>{data.title[0]} </span>
      <span className={styles.titlePartTwo}>{data.title[1]}</span>
      {!downSaleStatus && (
        <span className={styles.bottomText}>{data.bottom_text}</span>
      )}
      {downSaleStatus && (
        <span
          className={styles.bottomText}
          style={downSaleStatus && discountStyle}
        >
          {data.discount_bottom_text.map((text, index) => (
            <React.Fragment key={index}>
              {index === 1 || index === 3 ? (
                <span className={styles.bottomTextSpecial}>{text}</span>
              ) : (
                text
              )}{" "}
            </React.Fragment>
          ))}
        </span>
      )}
    </div>
  );
};

export default MainBlock;
