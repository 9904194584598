import React, { useState } from "react";
import { usePageContent } from "../../../../../../../context/PageContext";

import styles from "./VariantCStyle.module.css";

const VariantC = () => {
  const { productOrder } = usePageContent();

  const [data] = useState(productOrder.order_payment_form_text);

  return (
    <div className={styles.container}>
      <div className={styles.element}>
        <span className={styles.regularText}>{data.plan_name.title}</span>
        <span
          className={styles.regularText}
          style={{ textDecoration: "line-through" }}
        >
          {data.plan_name.cross_price}
        </span>
      </div>
      <div className={styles.element}>
        <span className={styles.regularText} style={{ color: "#FF5858" }}>
          {data.black_friday_discount.title}
        </span>
        <span className={styles.regularText} style={{ color: "#FF5858" }}>
          {data.black_friday_discount.discount_price}
        </span>
      </div>
      <div className={styles.element}>
        <span className={styles.regularText}>{data.due_on.title}</span>
        <span className={styles.regularText}>{data.due_on.price}</span>
      </div>
      <div className={styles.hr} />
      <div className={styles.element}>
        <span className={styles.totalText}>Total today:</span>
        <span className={styles.totalText}>${productOrder.total_price}</span>
      </div>
    </div>
  );
};

export default VariantC;
