import React from "react";
import { usePageContent } from "../../../../../context/PageContext";

import TopBlock from "./TopBlock/TopBlock";
import MainBlock from "./MainBlock/MainBlock";
import MainButton from "../../MainButton/MainButton";

import styles from "./LeftSectionStyle.module.css";

const LeftSection = ({ data }) => {
  const { downSaleStatus } = usePageContent();

  return (
    <div className={styles.leftSection}>
      <TopBlock data={data.top_block} />
      <MainBlock data={data.main_block} />
      <MainButton
        width={"242px"}
        height={"52px"}
        btnText={
          !downSaleStatus
            ? data.button_block.button_text[0]
            : data.button_block.button_text[1]
        }
        undBtnText={data.button_block.under_button_text}
      />
    </div>
  );
};

export default LeftSection;
