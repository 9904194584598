import React from "react";
import { useButtonRef } from "../../../context/ButtonRefContext";
import { usePaymentBlock } from "../../../context/PaymentBlockContext";

import UserNameEmailStep from "./UserNameEmailStep/UserNameEmailStep";

import LoaderIcon from "../../Plan/loaderIcon";
import { Loader } from "../../Plan/styles";

import styles from "./PaymentBlock.module.css";

const PaymentBlock = () => {
  const { loader, startNameEmailForm, loadingForm } = usePaymentBlock();

  const { paymentButtonRef } = useButtonRef();

  return (
    <div className={styles.PaymentFormContainer}>
      <button
        ref={paymentButtonRef}
        onClick={async () => {
          loadingForm();
        }}
        style={{
          display: "none",
        }}
      />
      {startNameEmailForm ? <UserNameEmailStep /> : null}

      {loader ? (
        <Loader>
          <LoaderIcon />
        </Loader>
      ) : null}
    </div>
  );
};

export default PaymentBlock;
