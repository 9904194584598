import React from "react";
import { usePromoCounter } from "../../../../../context/PromoIncrementCounerContext";

import styles from "../BottomComponentStyle.module.css";

const RightSection = ({ data }) => {
  const { counter } = usePromoCounter();

  return (
    <div className={styles.rightContainer}>
      <div className={styles.elementContainer}>
        <span className={styles.lifetimeText}>{data.lifetime_accounts}</span>
        <span className={styles.elementText}>{data.lifetime_text}</span>
      </div>
      <img
        src="../img/verticalArrowReserve.png"
        alt="verticalArrowReserve"
        className={styles.arrowImg}
      />
      <div className={styles.elementContainerTwo}>
        <div className={styles.titleContainer}>
          <img
            src="../img/peopleFrameReserve.png"
            alt="WaitlistPeople"
            className={styles.waitlistPeople}
          />
          <span className={styles.accountsText}>{counter}</span>
        </div>
        <span className={styles.elementText}>{data.waitlist_text}</span>
      </div>
    </div>
  );
};

export default RightSection;
