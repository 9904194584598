import React, { useEffect } from "react";
import { useRemoteConfig } from "../../context/RemoteConfigContext";
import { useErrorHandler } from "../../context/ErrorHandlerContext";
import { useEvent } from "../../context/EventContext";

import PageComponent from "../templates/PageComponent/PageComponent";
import ContainerComponent from "../templates/ContainerComponent/ContainerComponent";

import TopDiscount from "./TopDiscount/TopDiscount";
import TopComponent from "./TopComponent/TopComponent";

import PrizesContainer from "./PrizesContainer/PrizesContainer";
import WhatIsMemoryOS from "./WhatIsMemoryOS/WhatIsMemoryOS";
import WithinMemoryOS from "./WithinMemoryOS/WithinMemoryOS";
import YourGrandmaster from "./YourGrandmaster/YourGrandmaster";
import InfoImgBlock from "./InfoImgBlock/InfoImgBlock";
import MoreEfficiently from "./MoreEfficiently/MoreEfficiently";
import DidYouKnow from "./DidYouKnow/DidYouKnow";
import ReviewContainer from "./ReviewContainer/ReviewContainer";
import AnswerQuestions from "./AnswerQuestions/AnswerQuestions";
import RegInterest from "./RegInterest/RegInterest";
import ViewingPageCounter from "./ViewingPageCounter/ViewingPageCounter";
import JoinTheRest from "./JoinTheRest/JoinTheRest";
import FooterContainer from "./FooterContainer/FooterContainer";
import PaymentBlock from "./PaymentBlock/PaymentBlock";

import ErrorHandler from "../ErrorHandler/ErrorHandler";

const Waitlist = () => {
  const { showError } = useErrorHandler();
  const { componentsData } = useRemoteConfig();
  const { analyticsEvent } = useEvent();

  useEffect(() => {
    analyticsEvent("waitlistPageView");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getComponentByName = (name) => {
    switch (name) {
      case "TopDiscount":
        return <TopDiscount />;
      case "TopDoubleComponent":
        return <TopComponent />;
      case "PrizesContainer":
        return <PrizesContainer />;
      case "WhatIsMemoryOS":
        return <WhatIsMemoryOS />;
      case "WithinMemoryOS":
        return <WithinMemoryOS />;
      case "YourGrandmaster":
        return <YourGrandmaster />;
      case "InfoImgBlock":
        return <InfoImgBlock />;
      case "MoreEfficiently":
        return <MoreEfficiently />;
      case "DidYouKnow":
        return <DidYouKnow />;
      case "ReviewContainer":
        return <ReviewContainer />;
      case "AnswerQuestions":
        return <AnswerQuestions />;
      case "MoneyBackGuarantee":
        return <RegInterest />;
      case "ViewingPageCounter":
        return <ViewingPageCounter />;
      case "JoinTheRest":
        return <JoinTheRest />;

      default:
        return null;
    }
  };
  const backgroundColor = "none";
  const styleOpt = { width: "100%" };

  return (
    <PageComponent bgColor={backgroundColor}>
      <ContainerComponent styleOption={styleOpt}>
        {showError ? <ErrorHandler /> : null}
        {componentsData
          .sort((a, b) => a.position - b.position)
          .map((item, index) => (
            <React.Fragment key={index}>
              {item.showHide && getComponentByName(item.component)}
            </React.Fragment>
          ))}
        <PaymentBlock />
      </ContainerComponent>
      <FooterContainer />
    </PageComponent>
  );
};

export default Waitlist;
