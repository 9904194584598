import React, { useEffect, useState } from "react";
import { useEmailValidator } from "../../../../../context/EmailValidatorContext";

import styles from "./EmailContainerStyle.module.css";

const EmailContainer = ({
  placeholderInfo,
  updateValidUserDataAtIndex,
  updateUserData,
  invalidEmail,
}) => {
  const [newUserEmail, setNewUserEmail] = useState("");
  const [isValidEmailFormat, setIsValidEmailFormat] = useState(true);

  const { validEmail } = useEmailValidator();

  const [pasted, setPasted] = useState(false);
  const handleInputChange = (e) => {
    const { value } = e.target;
    const truncatedValue = value.slice(0, 50);

    if (!pasted) {
      setNewUserEmail(truncatedValue.toLowerCase());
      setIsValidEmailFormat(validEmail(truncatedValue));
    }

    setPasted(false);
  };

  const handlePaste = (e) => {
    const pastedValue = e.clipboardData.getData("text");
    const truncatedValue = pastedValue.slice(0, 50);
    setNewUserEmail(truncatedValue.toLowerCase());
    setIsValidEmailFormat(validEmail(truncatedValue));
    setPasted(true);
  };

  useEffect(() => {
    if (isValidEmailFormat && newUserEmail.length > 0) {
      updateValidUserDataAtIndex(0, true);
      updateUserData({ userEmail: newUserEmail });
    } else {
      updateValidUserDataAtIndex(0, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidEmailFormat, newUserEmail]);

  return (
    <div className={styles.emailStep}>
      {/* <span className={styles.textAboveInput}>{data.input.textAboveInput}</span> */}
      <input
        type="email"
        placeholder={placeholderInfo}
        name="email"
        autoComplete="email"
        required
        value={newUserEmail}
        onChange={handleInputChange}
        onPaste={handlePaste}
        className={
          !isValidEmailFormat ? styles.customInput : styles.customInput
        }
      />
      {!isValidEmailFormat && (
        <span className={styles.invalidText}>{invalidEmail}</span>
      )}
      {/* <span className={styles.textUnderInput}>{data.textUnderInput}</span> */}
      {/* <div className={styles.hrLine} /> */}
    </div>
  );
};

export default EmailContainer;
