import React from "react";
import { usePageConfig } from "../../../../../../context/PageConfigContext";

import styles from "./MarketButtonsStyle.module.css";

const MarketButtons = () => {
  const { findDataComponent } = usePageConfig();

  const data = findDataComponent("RedesignThankYou");

  return (
    <div className={styles.buttonContainer}>
      <a href={data.activateText.stepTwo.appStore.downloadLink} target="blank">
        <div className={styles.dwnlBtnStore}>
          <img
            src="/img/apple-black-logo-ty.png"
            alt="App Store"
            className={styles.btnImg}
          />
          <div className={styles.btnTextContainer}>
            <span className={styles.textHeader}>
              {data.activateText.stepTwo.appStore.header}
            </span>
            <span className={styles.textFooter}>
              {data.activateText.stepTwo.appStore.footer}
            </span>
          </div>
        </div>
      </a>
      <a
        href={data.activateText.stepTwo.googlePlay.downloadLink}
        target="blank"
      >
        <div className={styles.dwnlBtnStore}>
          <img
            src="/img/google-play-ty.png"
            alt="Google Play"
            className={styles.btnImg}
          />

          <div className={styles.btnTextContainer}>
            <span className={styles.textHeader}>
              {data.activateText.stepTwo.googlePlay.header}
            </span>
            <span className={styles.textFooter}>
              {data.activateText.stepTwo.googlePlay.footer}
            </span>
          </div>
        </div>
      </a>
    </div>
  );
};

export default MarketButtons;
