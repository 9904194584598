import React from "react";

import VariantB from "./RenderVariant/VariantB";

import styles from "./PleaseNoteStyle.module.css";

const PleaseNote = () => {
  return (
    <div className={styles.container}>
      <VariantB />
    </div>
  );
};

export default PleaseNote;
