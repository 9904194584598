import React, { useState } from "react";

import { useRemoteConfig } from "../../../../context/RemoteConfigContext";

import ElementsSlider from "../ElementsSlider/ElementsSlider";

import styles from "../WithinMemoryOS.module.css";

const VariantB = () => {
  const imgName = [
    "withinBlockComment.png",
    "withinBlockTimer.png",
    "withinBlockStar.png",
    "withinBlockUniversity.png",
    "withinBlockHeart.png",
    "withinBlockJug.png",
    "withinBlockBank.png",
  ];

  const { waitlistPageData } = useRemoteConfig();

  const [data] = useState(waitlistPageData.access_functionality);

  return (
    <>
      <div className={styles.secondContainer}>
        {data.main_block.map((text, index) => {
          return (
            <div key={index} className={styles.secondElement}>
              <img
                src={`/img/${imgName[index]}`}
                alt={`img${index}`}
                className={styles.secondImg}
              />
              {text.length > 1 ? (
                <>
                  <span className={styles.solidText}>{text[0]} </span>
                  <span
                    className={`${styles.secondStepText} ${styles.stepTextMargin}`}
                  >
                    {text[1]}
                  </span>
                </>
              ) : (
                <span className={styles.secondStepText}>{text[0]}</span>
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.hrLine} />
      <ElementsSlider renderData={data.bottom_block} />
    </>
  );
};

export default VariantB;
