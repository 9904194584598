import React from "react";
import { usePageContent } from "../../../../context/PageContext";

import RatingContainer from "./RatingContainer/RatingContainer";
import LogoGlobal from "../../../templates/Logo/LogoGlobal";

import styles from "./InfoTitle.module.css";

const InfoTitle = () => {
  const { pageContent } = usePageContent();

  return (
    <div className={styles.InfoTitleContainer}>
      {true && (
        <div className={styles.InfoTitleLogo}>
          <LogoGlobal />
        </div>
      )}

      <div className={styles.InfoTitleContainerHeader}>
        {pageContent.header_block.title}
      </div>
      <RatingContainer />
      <div className={styles.InfoTitleContainerRatingText}>
        {pageContent.header_block.rating_text}
      </div>
    </div>
  );
};

export default InfoTitle;
