import React, { useEffect, useRef } from "react";

import { useScroll } from "../../../../context/ScrollContext";
import { usePageConfig } from "../../../../context/PageConfigContext";
import { usePageContent } from "../../../../context/PageContext";

import FooterBlock from "./FooterBlock/FooterBlock";
import ImgPlanBlock from "./ImgPlanBlock/ImgPlanBlock";
import VariantС from "../ABTestPlanContainer/VariantC/VariantС";
import HeaderVariantB from "./HeaderRender/HeaderVariantB";

import styles from "./ChoosePlan.module.css";
import ActiveDownsale from "./ActiveDownsale/ActiveDownsale";

const ChoosePlan = () => {
  const { downSaleStatus } = usePageContent();
  const choosePlanRef = useRef(null);
  const { findDataComponent } = usePageConfig();
  const { scrollRefOne } = useScroll();

  const data = findDataComponent("ChoosePlan");

  useEffect(() => {
    scrollRefOne.current = choosePlanRef.current;
  }, [scrollRefOne]);

  return (
    <div className={styles.bigContainer} ref={choosePlanRef}>
      <ImgPlanBlock />
      <div className={styles.ChoosePlanContainer}>
        {downSaleStatus && <ActiveDownsale />}
        <HeaderVariantB />

        <VariantС />

        <FooterBlock
          data={data.footerBlock}
          footerData={data.secondTextInfoBlock}
        />
      </div>
    </div>
  );
};

export default ChoosePlan;
