import React from "react";
import { usePageContent } from "../../../../../context/PageContext";

import SafeIconSVG from "./assets/SafeIcon";
import GraySafeBlock from "./GraySafeBlock/GraySafeBlock";

import FooterTextContainerTwo from "../../ChoosePlanSecond/FooterBlock/FooterTextContainer/FooterTextContainerTwo";

import styles from "./FooterBlockStyle.module.css";

const FooterBlock = ({ data }) => {
  const { selectedProduct, toggleStatus } = usePageContent();

  return (
    <div className={styles.container}>
      <GraySafeBlock />

      <div className={styles.FooterSafe}>
        <div className={styles.FooterSafeIcon}>
          <SafeIconSVG />
        </div>
        <span className={styles.FooterSafeText}>{data.footerText}</span>
      </div>

      <FooterTextContainerTwo
        renderData={
          !toggleStatus
            ? selectedProduct?.first_subscription_options_bottom_text
            : selectedProduct?.trial_first_subscription_options_bottom_text
        }
      />
    </div>
  );
};

export default FooterBlock;
