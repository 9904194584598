import React, { useRef } from "react";

import { useResizeContainer } from "../../../../hooks/resizeContainer";

import { usePageConfig } from "../../../../context/PageConfigContext";

import YouTubePlayer from "../../../templates/YoutubePlayer/YoutubePlayer";

import styles from "./WhatIsMemoryOS.module.css";

const WhatIsMemoryOS = () => {
  const { findDataComponent } = usePageConfig();
  const data = findDataComponent("WhatIsMemoryOS");

  const containerWhatIsMemoryOSRef = useRef(null);

  const { widthCont, heightCont, radiusCont } = useResizeContainer(
    containerWhatIsMemoryOSRef
  );

  return (
    <div className={styles.container}>
      <span className={styles.headText}>{data.headerText}</span>
      <div className={styles.videoContainer} ref={containerWhatIsMemoryOSRef}>
        <YouTubePlayer
          videoId={data.youtube_video_id}
          width={widthCont}
          height={heightCont}
          borderRadius={radiusCont}
        />
      </div>
      <div className={styles.backgroundImg} />
    </div>
  );
};

export default WhatIsMemoryOS;
