import React, { useRef } from "react";

import {
  getPreloadedImageUrl,
  usePreloadedImages,
} from "../../../../context/PreloadedImagesContext";

// import VideoPlayer from "./VideoPlayer/VideoPlayer";

import Loading from "../Loading/Loading";

import { Step, Column, Title, Button } from "../../styles";
import styles from "./StaticStepStyles.module.css";
import { useResizeContainer } from "../../../../hooks/resizeContainer";
import YouTubePlayer from "../../../templates/YoutubePlayer/YoutubePlayer";

const StaticStep = ({ id, step, handleNextStep }) => {
  const { imagesMap, isLoading } = usePreloadedImages();
  const imageUrl = step.image
    ? getPreloadedImageUrl(imagesMap, step.image)
    : null;

  const staticStepRef = useRef(null);

  const { widthCont, heightCont, radiusCont } =
    useResizeContainer(staticStepRef);

  return (
    <Step>
      {step.image || step.video ? (
        <>
          <Column>
            {step.image && (
              <div className={styles.imageContainer}>
                {isLoading && <Loading />}
                <img className={styles.image} src={imageUrl} alt={step.title} />
              </div>
            )}

            {step.video && (
              <div className={styles.videoPlayer} ref={staticStepRef}>
                <YouTubePlayer
                  videoId={step.youtube_video_id}
                  width={widthCont}
                  height={heightCont}
                  borderRadius={radiusCont}
                />
              </div>
            )}
          </Column>
          <Column>
            <Title>{step.title}</Title>

            {step.copy.map((copy, index) => (
              <span key={`${id}-${index}`} className={styles.copyText}>
                {copy}
              </span>
            ))}

            <Button
              $isActive={true}
              onClick={() => handleNextStep({ id, type: "static" })}
            >
              Continue
            </Button>
          </Column>
        </>
      ) : (
        <div className={styles.noImgStyle}>
          <Column>
            <Title>{step.title}</Title>
            {step.copy.map((copy, index) => (
              <span key={`${id}-${index}`} className={styles.copyText}>
                {copy}
              </span>
            ))}
            <Button
              $isActive={true}
              onClick={() => handleNextStep({ id, type: "static" })}
            >
              Continue
            </Button>
          </Column>
        </div>
      )}
    </Step>
  );
};

export default StaticStep;
